import { css } from 'twin.macro';
import { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Spinner } from 'react-bootstrap';

import ScrollToBottom from 'react-scroll-to-bottom';
import InfiniteScroll from 'react-infinite-scroller';

import Message from './Message';
import MessageReactions from './MessageReactions';

//redux
import { connect } from 'react-redux';
import { getTeamChat, postLastSeenRoomMessage } from '../../../_redux/actions/ActionMessage';

import '../Common/Messages.css';
import NewMessageButton from './NewMessageButton';
import { getTheDate } from '../../../_helpers';
import { createRef } from 'react';

const Messages = ({
    messages,
    roomId,
    roomType,
    totalMessages,
    setChatFormId,
    getTeamChat,
    isSearch,
    setReply,
    isUploading,
    botInfo,
    lastSeen,
    postLastSeenRoomMessage,
    userRole,
    teamData,
    newMessageRef,
}) => {
    const [messageId, setMessageId] = useState();

    useEffect(() => {
        var objDiv = document.querySelector('.m-c');
        objDiv.scrollTop = objDiv.scrollHeight;
    }, []);

    useEffect(() => {
        if (lastSeen !== messages[messages.length - 1].id) {
            const lastSeenMessage = {
                user: localStorage.getItem('userId'),
                room: roomId,
                message: messages[messages.length - 1].id,
            };
            postLastSeenRoomMessage(lastSeenMessage);
        }
    }, [lastSeen, messages, postLastSeenRoomMessage, roomId]);

    const refs = messages.reduce((acc, value) => {
        acc[value.id] = createRef();
        return acc;
    }, {});

    // takes messages and returns an object of messages object grouped with respect to
    // their dateAndTime.
    // {
    // 		dateAndTime: [Array(9)],
    // 		dateAndTime: [Array(3)],
    // }

    const groups = messages.reduce((groups, message) => {
        const date = getTheDate(new Date(message.dateAndTime));
        if (!groups[date]) {
            groups[date] = [];
        }
        groups[date].push(message);
        return groups;
    }, {});

    const loadFunc = (pages) => {
        getTeamChat(roomId, pages);
    };

    const handleClose = () => {
        setMessageId(null);
    };

    const showMessageReactions = (id) => {
        setMessageId(id);
    };

    const ongoingTasks = [
        {
            id: 1,
            name: 'Nitro 5 gaming time',
            startAt: '2023-7-4 2:30 pm',
            users: ['adit', 'sandeep'],
        },
        {
            id: 2,
            name: 'Office time',
            startAt: '2023-7-4 5:30 pm',
            users: ['adit'],
        },
    ];

    return (
        <Fragment>
            <ScrollToBottom
                behavior="scrollToEnd"
                className="messages"
                scrollViewClassName="m-c"
                followButtonClassName="scroll-btn"
            >
                <InfiniteScroll
                    pageStart={1}
                    initialLoad={false}
                    loadMore={loadFunc}
                    hasMore={isSearch ? false : messages.length < totalMessages}
                    loader={
                        <div className="loader" key={0}>
                            <img
                                src="/images/ball-triangle.svg"
                                css={css`
                                    width: 2rem;
                                    height: 2rem;
                                `}
                                alt="loading"
                            />
                        </div>
                    }
                    useWindow={false}
                    isReverse={true}
                    threshold={100}
                >
                    {Object.keys(groups).map((date) => (
                        <Message
                            setReply={setReply}
                            date={date}
                            rId={roomId}
                            message={groups[date]}
                            setChatFormId={setChatFormId}
                            messages={messages}
                            refs={refs}
                            key={date}
                            roomType={roomType}
                            userRole={userRole}
                            lastSeen={
                                lastSeen !== messages[messages.length - 1].id &&
                                messages[messages.length - 1].userId !== localStorage.getItem('userId')
                                    ? lastSeen
                                    : ''
                            }
                            showMessageReactions={showMessageReactions}
                            teamData={teamData}
                        />
                    ))}
                    {isUploading && (
                        <Fragment>
                            <span className="small text-muted">Uploading</span>{' '}
                            <Spinner animation="grow" variant="primary" size="sm" />
                        </Fragment>
                    )}
                    {botInfo && botInfo?.name && (
                        <Fragment>
                            <img src={botInfo?.image} alt="" style={{ height: '20px', width: '20px' }} />
                            <span className="small text-muted">{botInfo?.activity || ''}</span>
                            <Spinner animation="grow" variant="primary" size="sm" />
                        </Fragment>
                    )}
                </InfiniteScroll>
                <NewMessageButton newMessageRef={newMessageRef} />
            </ScrollToBottom>

            <Modal show={Boolean(messageId)} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <h1>Message Reactions</h1>
                </Modal.Header>
                <Modal.Body>
                    <MessageReactions roomId={roomId} messageId={messageId} />
                </Modal.Body>
            </Modal>
        </Fragment>
    );
};
Messages.propTypes = {
    messages: PropTypes.array.isRequired,
    isSearch: PropTypes.bool.isRequired,
    roomId: PropTypes.string.isRequired,
    totalMessages: PropTypes.number.isRequired,
    getTeamChat: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    isUploading: state.messages.isUploading,
    botInfo: state.messages.botInfo,
});

export default connect(mapStateToProps, { getTeamChat, postLastSeenRoomMessage })(Messages);
