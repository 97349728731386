import React, { useMemo } from 'react';
import { Form, Col } from 'react-bootstrap';
import Linkify from 'react-linkify';
import { marginColor } from './Checkbox';
import { ElementStyled, elementClass } from './styles/ElementStyles';

const FullName = ({ data, elementRef, isPreview, error }) => {
    const value = useMemo(() => {
        try {
            return JSON.parse(data.value);
        } catch (error) {
            return null;
        }
    }, [data.value]);

    return (
        <ElementStyled className={elementClass(data)}>
            <div>
                <h5 className="warpIt">
                    {data.fieldName}
                    <span css={marginColor}>{(data.hasOwnProperty('required') ? data.required : true) && '*'}</span>
                </h5>

                {data.instruction && (
                    <pre className="small text-muted warpIt preStyles">
                        <Linkify
                            componentDecorator={(decoratedHref, decoratedText, key) => (
                                <a target="blank" href={decoratedHref} key={key}>
                                    {decoratedText}
                                </a>
                            )}
                        >
                            {data.instruction}
                        </Linkify>
                    </pre>
                )}
            </div>

            <Form.Row>
                {(Array.isArray(data?.data) ? data.data : []).map((field, i) => (
                    <Form.Group key={field.key} as={Col} md="6" controlId="formGridEmail">
                        <Form.Control
                            type={field.type}
                            ref={
                                elementRef &&
                                elementRef({
                                    required: data.hasOwnProperty('required') ? data.required : true,
                                })
                            }
                            name={`${!isPreview ? `FullName-${data._id}.${field.key}` : 'FullName'}`}
                            disabled={isPreview}
                            defaultValue={value ? value[field.key] : field.defaultValue || field.value}
                        />
                        <Form.Label>{field.name}</Form.Label>
                        <Form.Text className="text-danger">
                            {error && error[field.key]?.type === 'required' && 'This field is required'}
                        </Form.Text>
                    </Form.Group>
                ))}
            </Form.Row>
        </ElementStyled>
    );
};

export default FullName;
