import { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';

import { WebSocketContext } from '../../../_redux/actions/ActionSocket';
import { fetchWrapper } from '../../../_helpers/fetchWrapper';
import {
    setSocketMessages,
    postLastSeenRoomMessage,
    getNotSeenRooms,
    setSocketRecentChats,
    postLastSeenDirectMessage,
    postLastSeenSubChannelMessage,
    stopAccessingBot,
} from '../../../_redux/actions/ActionMessage';
import { swDev } from '../../../swDev';

function MessageListener({
    setSocketMessages,
    postLastSeenRoomMessage,
    getNotSeenRooms,
    setSocketRecentChats,
    postLastSeenDirectMessage,
    postLastSeenSubChannelMessage,
    stopAccessingBot,
}) {
    const ws = useContext(WebSocketContext);
    const dispatch = useDispatch();
    const { roomId } = useParams();
    const { userId } = useParams();
    const { subChannelId } = useParams();

    useEffect(() => {
        const CurrUser = localStorage.getItem('userId');
        if (ws && ws.socket) {
            ws.socket.on('message', async (message) => {
                if (message.subChannel) {
                    if (message.event === 'created') {
                        const tempMessage = [
                            {
                                id: message.id,
                                file: message.file,
                                files: message.files,
                                images: message.images,
                                data: message.data,
                                type: message.type,
                                user: message.user,
                                subChannel: message.subChannel,
                                createdAt: message.createdAt,
                                visibleTo: message.visibleTo,
                            },
                        ];
                        if (message.subChannel) {
                            if (message.user.id !== CurrUser) {
                                const lastSeenMessage = {
                                    user: CurrUser,
                                    subChannel: subChannelId,
                                    message: message.id,
                                };

                                await postLastSeenSubChannelMessage(lastSeenMessage);
                            }
                            dispatch({ type: 'IS_NEW_MESSAGE', payload: true });
                            dispatch({
                                type: 'SET_LAST_SEEN_MESSAGE',
                                payload: { id: subChannelId, message: message.id },
                            });

                            setSocketMessages({ response: tempMessage[0], id: message.subChannel });
                        } else {
                            // dispatch(getNotSeenRooms(message.room));
                            setSocketMessages({ response: tempMessage[0], id: message.room });
                        }
                        if (message.user.id !== CurrUser) {
                            try {
                                const tempMessageData = JSON.parse(message.data);
                                tempMessageData.id = message.user.id;
                                tempMessageData.type = 'team';
                                tempMessageData.isReply =
                                    tempMessageData.reply && tempMessageData.reply.user !== CurrUser ? true : false;
                                let notificationData = {
                                    subs: await swDev(),
                                    message:
                                        message.file || message.files || message.images
                                            ? 'Sent a File'
                                            : JSON.stringify(tempMessageData),
                                    userId: message.user.id,
                                };

                                await fetchWrapper.post('notification/subscription', notificationData);
                            } catch (err) {
                                console.log(err);
                            }
                        }
                    } else if (message.event === 'updated') {
                        // if (CurrUser !== message.user) {
                        dispatch({
                            type: 'UPDATE_SEND_MESSAGE',
                            payload: { id: message.subChannel, messageId: message.id, message: message },
                        });
                        // }
                    } else if (message.event === 'deleted') {
                        dispatch({
                            type: 'REMOVE_SEND_MESSAGE',
                            payload: { id: message.subChannel, messageId: message.id },
                        });
                    } else if (message.event === 'emoji') {
                        dispatch({
                            type: 'MESSAGE_EMOJI',
                            payload: {
                                id: message.subChannel,
                                messageId: message.id,
                                emoji: message.emoji,
                                currUser: CurrUser,
                            },
                        });
                    }
                } else if (message.isDirectMessage === false) {
                    //Team Chat
                    // console.log(message, 'message', message.room === roomId, 'same room message and room')

                    if (message.event === 'created') {
                        const conferenceData = message.conferenceData || {};
                        conferenceData.startedAt = new Date().toISOString();
                        const tempMessage = [
                            {
                                id: message.id,
                                file: message.file,
                                files: message.files,
                                images: message.images,
                                data: message.data,
                                type: message.type,
                                userName: message.user.name,
                                user: message.user,
                                room: message.room,
                                createdAt: message.createdAt,
                                visibleTo: message.visibleTo,
                                conferenceData: conferenceData,
                                conference: message.conference,
                                requestedBy: message.requestedBy,
                            },
                        ];

                        if (message.room === roomId) {
                            if (message.user.id !== CurrUser) {
                                const lastSeenMessage = {
                                    user: CurrUser,
                                    room: roomId,
                                    message: message.id,
                                };

                                await postLastSeenRoomMessage(lastSeenMessage);
                            }
                            // console.log(message.room, roomId, "IS NEW MESSAGE SET BELOW")
                            // console.log("NEW MESSAGE LISTENER< ACTIVE")
                            dispatch({ type: 'IS_NEW_MESSAGE', payload: true });
                            dispatch({ type: 'SET_LAST_SEEN_MESSAGE', payload: { id: roomId, message: message.id } });

                            setSocketMessages({ response: tempMessage[0], id: message.room });
                            if (message?.user?.username === 'optonomebot') {
                                stopAccessingBot();
                            }
                        } else {
                            dispatch(getNotSeenRooms(message.room));
                            setSocketMessages({ response: tempMessage[0], id: message.room });
                        }
                        if (message.user.id !== CurrUser) {
                            try {
                                const tempMessageData = JSON.parse(message.data);
                                tempMessageData.id = message.user.id;
                                tempMessageData.type = 'team';
                                tempMessageData.isReply =
                                    tempMessageData.reply && tempMessageData.reply.user !== CurrUser ? true : false;
                                let notificationData = {
                                    subs: await swDev(),
                                    message:
                                        message.file || message.files || message.images
                                            ? 'Sent a File'
                                            : JSON.stringify(tempMessageData),
                                    userId: message.user.id,
                                };

                                await fetchWrapper.post('notification/subscription', notificationData);
                            } catch (err) {
                                console.log(err);
                            }
                        }
                    } else if (message.event === 'updated') {
                        // if (CurrUser !== message.user) {
                        dispatch({
                            type: 'UPDATE_SEND_MESSAGE',
                            payload: { id: message.room, messageId: message.id, message: message },
                        });
                        // }
                    } else if (message.event === 'deleted') {
                        dispatch({
                            type: 'REMOVE_SEND_MESSAGE',
                            payload: { id: message.room, messageId: message.id },
                        });
                    } else if (message.event === 'emoji') {
                        dispatch({
                            type: 'MESSAGE_EMOJI',
                            payload: {
                                id: message.room,
                                messageId: message.id,
                                emoji: message.emoji,
                                currUser: CurrUser,
                            },
                        });
                    }
                } else {
                    // Direct Chat
                    if (message.event === 'created') {
                        const tempMessage = [
                            {
                                file: message.file,
                                files: message.files,
                                images: message.images,
                                id: message.id,
                                data: message.data,
                                type: message.type ? message.type : 'message',
                                fromUser: message?.fromUser?.id,
                                toUser: message?.toUser?.id,
                                createdAt: message.createdAt,
                            },
                        ];
                        if (CurrUser === message.fromUser.id) {
                            setSocketMessages({ response: tempMessage[0], id: message.toUser.id });
                        } else if (!(message.fromUser.id !== userId && message.toUser.id !== userId)) {
                            const lastSeenMessage = {
                                fromUser: localStorage.getItem('userId'),
                                toUser: message.toUser.id,
                                message: message.id,
                            };
                            await postLastSeenDirectMessage(lastSeenMessage);
                            dispatch({ type: 'IS_NEW_MESSAGE', payload: true });
                            dispatch({
                                type: 'SET_LAST_SEEN_MESSAGE',
                                payload: { id: message.fromUser.id, message: message.id },
                            });
                        }
                        if (CurrUser !== message.fromUser.id) {
                            setSocketMessages({ response: tempMessage[0], id: message.fromUser.id });
                        }

                        if (CurrUser === message.fromUser.id) {
                            tempMessage[0].sent = true;
                            tempMessage[0].notSeen = true;
                            tempMessage[0].user = {
                                id: message.toUser.id,
                                name: message.toUser.name,
                                avatar: message.toUser.avatar,
                            };
                            setSocketRecentChats(tempMessage);
                        } else {
                            tempMessage[0].sent = false;
                            tempMessage[0].notSeen = true;
                            tempMessage[0].user = {
                                id: message.fromUser.id,
                                name: message.fromUser.name,
                                avatar: message.fromUser.avatar,
                            };
                            setSocketRecentChats(tempMessage);
                            const tempMessageData = JSON.parse(message.data);
                            tempMessageData.id = message.fromUser.id;
                            tempMessageData.type = 'private';
                            let notificationData = {
                                subs: await swDev(),
                                message: message.file ? 'Sent a File' : JSON.stringify(tempMessageData),
                                userId: message.fromUser.id,
                            };

                            await fetchWrapper.post('notification/subscription', notificationData);
                        }
                    } else if (message.event === 'updated') {
                        if (CurrUser === message.toUser) {
                            dispatch({
                                type: 'UPDATE_SEND_MESSAGE',
                                payload: {
                                    id: message.fromUser,
                                    messageId: message.id,
                                    message: {
                                        ...message,
                                        data: message.data,
                                        visibleTo: message.visibleTo,
                                        isEdited: true,
                                    },
                                },
                            });
                        }
                    } else if (message.event === 'deleted') {
                        if (CurrUser === message.toUser) {
                            dispatch({
                                type: 'REMOVE_SEND_MESSAGE',
                                payload: { id: message.fromUser, messageId: message.id },
                            });
                        }
                    }
                }
            });
        }

        return () => {
            if (ws && ws.socket) {
                ws.socket.off('message');
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ws]);
    return null;
}

export default connect(null, {
    setSocketMessages,
    postLastSeenRoomMessage,
    getNotSeenRooms,
    setSocketRecentChats,
    postLastSeenDirectMessage,
    postLastSeenSubChannelMessage,
    stopAccessingBot,
})(MessageListener);
