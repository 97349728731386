import { css } from 'twin.macro';
import { FormLabel, InputGroup } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { fetchWrapper, getTeamIdFromURL, toastWrapper, getChannelIdFromSettingsURL } from '../../../../../_helpers';
import { connect } from 'react-redux';
import { ActionStyles } from '../../../../Admin/reusableStyles';

//assets
import { VscLoading } from 'react-icons/vsc';
import FormSvg from '../../../../../assets/forms.svg';
import SortedTableHeaderIcon from '../../../../Admin/SortedTableHeaderIcon';
import Pagination from '../../../../Admin/Pagination';
import ChannelSelectFilter from '../../../../Admin/ChannelSelectFilter';
import VisitTemplateModal from './VisitTemplateModal';
import { FormPackageTable } from '../TeamSettingFormPackage';
import VisitTemplateAccordionModal from './VisitTemplateAccordionModal';
import { ToolTip } from '../../../../../_elements/ToolTip';
import { BsTrash } from 'react-icons/bs';
import Swal from 'sweetalert2';

const TeamSettingsVisitTemplate = ({ search, teamRooms }) => {
    const [visitTemplates, setVisitTemplates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [deleting, setDeleting] = useState(null);
    const teamId = getTeamIdFromURL();
    const channelIdFromUrl = getChannelIdFromSettingsURL();
    const [channel, setChannel] = useState(null);
    const [fetchToggle, setFetchToggle] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalResults, setTotalResults] = useState(10);
    const [rooms, setRooms] = useState([]);
    const [sortBy, setSortBy] = useState({
        key: 'createdAt',
        direction: 'desc',
    });

    useEffect(() => {
        if (teamId && teamRooms[teamId]) {
            setRooms(teamRooms[teamId]?.rooms);
        }
    }, [teamRooms, teamId]);

    useEffect(() => {
        const getVisitTemplate = async () => {
            try {
                setLoading(true);
                const res = await fetchWrapper.get(
                    `visit-templates?page=${currentPage}&limit=10${search ? `&name=${search}` : ''}&sortBy=${sortBy.key}:${sortBy.direction}${channel || channelIdFromUrl ? `&chatroom=${channel?.value || channel?._id || channelIdFromUrl}` : teamId ? `&team=${teamId}` : ''}`
                );
                setVisitTemplates(res.results);
                setTotalResults(res.totalResults);
            } catch (error) {
                console.log('ERROR => ', error);
                toastWrapper.error(error?.message);
            } finally {
                setLoading(false);
            }
        };
        getVisitTemplate();
    }, [teamId, sortBy, search, currentPage, channel, fetchToggle]);

    const handleSort = (fieldName) => {
        setSortBy({ direction: sortBy.direction === 'asc' ? 'desc' : 'asc', key: fieldName });
    };

    const handleChangeChannel = (value) => {
        setChannel(value);
    };

    const handleUpdate = (updatedValue) => {
        setVisitTemplates((prev) =>
            prev?.map((template) =>
                template?._id === updatedValue?._id ? { ...template, ...updatedValue } : { ...template }
            )
        );
    };

    const handleDelete = async (id) => {
        try {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: 'The visit template will be removed from the team',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#50aeb0',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel',
            });
            if (result?.isConfirmed) {
                if (!id) {
                    toastWrapper.error('Please provide valid visit-template id');
                }
                setDeleting(id);
                await fetchWrapper.delete(`visit-templates/${id}`);
                setVisitTemplates((prev) => prev?.filter((template) => template?._id !== id));
                toastWrapper.success('Visit Template Deleted Successfully');
            }
        } catch (err) {
            console.log('err', err);
        } finally {
            setDeleting(null);
        }
    };

    return (
        <div>
            <div className="top-items flex justify-content-space-between align-items-end">
                <div
                    className="row"
                    css={css`
                        position: relative;
                        display: flex;
                        width: 100%;
                        align-items: end;
                        z-index: 20;
                        gap: 5px;
                        margin-left: 4px;
                    `}
                >
                    <div
                        css={css`
                            width: 264px;
                        `}
                    >
                        <InputGroup className="flex-column">
                            <FormLabel className="mr-2 ">Channel: </FormLabel>
                            <ChannelSelectFilter
                                assignedChannel={channelIdFromUrl}
                                selectedRoomOption={channel}
                                onRoomSelect={handleChangeChannel}
                                disabled={!!channelIdFromUrl}
                                placeholder="Enter Channel Name"
                                className="bolder"
                                css={css`
                                    width: 264px;
                                `}
                            />
                        </InputGroup>
                    </div>
                </div>
                <div
                    css={css`
                        display: flex;
                        flex-direction: row;
                        gap: 4px;
                    `}
                >
                    <VisitTemplateModal mode={'Create'} setFetchToggle={setFetchToggle} channelId={channelIdFromUrl} />
                </div>
            </div>
            {loading ? (
                <h4 className="text-center">
                    <VscLoading className="spin" fontSize="42" />
                </h4>
            ) : visitTemplates?.length > 0 ? (
                <div className="custom-card">
                    <FormPackageTable className="w-100">
                        <thead>
                            <tr>
                                <th
                                    onClick={() => handleSort('createdAt')}
                                    css={css`
                                        color: inherit;
                                        cursor: pointer;
                                    `}
                                >
                                    S.N
                                    <SortedTableHeaderIcon sortConfig={sortBy} fieldName={'createdAt'} />
                                </th>
                                <th>Visit Template Name</th>
                                <th>Channel</th>
                                <th className="text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {visitTemplates?.map((templateData, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{10 * (currentPage - 1) + index + 1}</td>
                                        <td
                                            className="d-flex align-items-center"
                                            css={css`
                                                flex: 1;
                                            `}
                                        >
                                            <div
                                                css={css`
                                                    display: flex;
                                                    flex-direction: column;
                                                    gap: 0.5rem;
                                                `}
                                            >
                                                <div
                                                    css={css`
                                                        display: flex;
                                                        flex-direction: row;
                                                    `}
                                                >
                                                    <span
                                                        css={css`
                                                            white-space: nowrap;
                                                            overflow: hidden;
                                                            text-overflow: ellipsis;
                                                            max-width: 50rem;
                                                        `}
                                                    >
                                                        {templateData?.name}
                                                    </span>
                                                    {templateData?.archived ? (
                                                        <span className="badge badge-danger mx-2">Archived</span>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            {rooms?.find((room) => room?._id === templateData?.chatroom)?.title || '-'}
                                        </td>
                                        <td>
                                            <ActionStyles className="mt-2">
                                                <VisitTemplateAccordionModal mode={'view'} data={templateData} />
                                                <VisitTemplateModal
                                                    mode={'Edit'}
                                                    data={templateData}
                                                    handleUpdate={handleUpdate}
                                                    channelId={channelIdFromUrl}
                                                />
                                                <ToolTip
                                                    text={'Delete'}
                                                    side={'top'}
                                                    cssStyles={'top:-70% !important; min-width: fit-content !important'}
                                                >
                                                    {deleting === templateData?._id ? (
                                                        <VscLoading
                                                            className="spin"
                                                            css={css`
                                                                margin-left: 18px;
                                                            `}
                                                        />
                                                    ) : (
                                                        <BsTrash
                                                            className="icon delete"
                                                            onClick={() =>
                                                                handleDelete(templateData?._id || templateData?.id)
                                                            }
                                                        />
                                                    )}
                                                </ToolTip>
                                            </ActionStyles>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </FormPackageTable>
                </div>
            ) : (
                <div
                    css={css`
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                    `}
                >
                    <img
                        src={FormSvg}
                        alt={'no visit templates found'}
                        css={css`
                            width: 350px;
                            margin: 1rem 0;
                        `}
                    />
                    <h2 className="text-center">No Visit Templates</h2>
                </div>
            )}
            <div className="p-4">
                <Pagination
                    totalRecords={totalResults}
                    key={totalResults}
                    pageLimit={10}
                    pageNeighbours={1}
                    onPageChanged={(data) => {
                        setCurrentPage(Math.max(1, data.currentPage));
                    }}
                />
            </div>
        </div>
    );
};
const mapStateToProps = (state) => ({
    teamRooms: state.rooms.teamRooms,
});

export default connect(mapStateToProps, {})(TeamSettingsVisitTemplate);
