import dayjs from 'dayjs';

import * as ActionTypes from '../actions/ActionTypes';
var isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);

export default function Messages(
    state = {
        isLoading: false,
        errorMessage: null,
        searchFilters: {},
        messages: {},
        searchMessages: [],
        isSearch: false,
        totalSearchMessages: 0,
        isUploading: false,
        isNewMessage: false,
        botInfo: {},
    },
    action
) {
    let tempMessage, index;
    switch (action.type) {
        case ActionTypes.SET_MESSAGES:
            tempMessage = Object.assign({}, state.messages);
            if (!tempMessage[action.payload.id]) {
                tempMessage[action.payload.id] = {
                    message: [...action.payload.response.results],
                    totalMessages: action.payload.response.totalResults,
                };
            } else {
                tempMessage[action.payload.id].message.push(...action.payload.response.results);
                tempMessage[action.payload.id].totalMessages = action.payload.response.totalResults;
            }

            return {
                ...state,
                messages: tempMessage,
            };

        case ActionTypes.SET_SOCKET_MESSAGE:
            tempMessage = Object.assign({}, state.messages);
            if (tempMessage[action.payload.id]) {
                const messageIndex = tempMessage[action.payload.id].message
                    .slice(Math.max(tempMessage[action.payload.id].message.length - 5, 0))
                    .findIndex((mess) => mess.id === action.payload.response.id);
                if (messageIndex === -1) {
                    tempMessage[action.payload.id].message.unshift(action.payload.response);
                    tempMessage[action.payload.id].totalMessages += 1;
                }
            }
            return {
                ...state,
                messages: tempMessage,
            };

        case ActionTypes.REMOVE_SEND_MESSAGE:
            tempMessage = Object.assign({}, state.messages);
            if (tempMessage[action.payload.id]) {
                const messageIdToRemove = action.payload.messageId;
                const messageIndexToRemove = tempMessage[action.payload.id].message.findIndex(
                    (msg) => msg.id === messageIdToRemove
                );
                if (messageIndexToRemove !== -1) {
                    tempMessage[action.payload.id].message.splice(messageIndexToRemove, 1);
                    tempMessage[action.payload.id].totalMessages -= 1;
                }
            }
            return {
                ...state,
                messages: tempMessage,
            };

        case ActionTypes.UPDATE_SEND_MESSAGE:
            // handles both message text edit and message role edit (Making message reappear in the chat when role matchs)
            tempMessage = Object.assign({}, state.messages);
            if (tempMessage[action.payload.id]) {
                index = tempMessage[action.payload.id].message.findIndex((org) => org.id === action.payload.messageId);
                if (index >= 0) {
                    tempMessage[action.payload.id].message[index] = {
                        ...tempMessage[action.payload.id].message[index],
                        ...action.payload.message,
                    };
                } else {
                    // checking if room has messages
                    if (tempMessage[action.payload.id].message.length) {
                        tempMessage[action.payload.id].message = tempMessage[action.payload.id].message.sort(
                            function (a, b) {
                                return a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0;
                            }
                        );
                        let latestMessage = tempMessage[action.payload.id].message[0];

                        let oldestMessage =
                            tempMessage[action.payload.id].message[tempMessage[action.payload.id]?.message?.length - 1];
                        let oldestMessageCreatedTime = dayjs(oldestMessage.createdAt).format('YYYY-MM-DD');

                        let lastestMessageCreatedTime = dayjs(latestMessage.createdAt).format('YYYY-MM-DD');

                        let updateMessage = action.payload.message;
                        let updateMessageCreatedTime = dayjs(updateMessage.createdAt).format('YYYY-MM-DD');

                        if (
                            tempMessage[action.payload.id].message.length === 0 ||
                            dayjs(updateMessageCreatedTime).isBetween(
                                lastestMessageCreatedTime,
                                oldestMessageCreatedTime,
                                null,
                                '[]'
                            ) ||
                            dayjs(updateMessageCreatedTime).diff(lastestMessageCreatedTime)
                        ) {
                            tempMessage[action.payload.id].message = [
                                ...tempMessage[action.payload.id].message,
                                action.payload.message,
                            ].sort(function (a, b) {
                                return a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0;
                            });
                        }
                    } else {
                        tempMessage[action.payload.id].message = [action.payload.message];
                    }
                }
            }
            return {
                ...state,
                messages: tempMessage,
            };

        case ActionTypes.SET_CONFERENCE_END:
            tempMessage = Object.assign({}, state.messages);
            if (tempMessage[action.payload.room]) {
                index = tempMessage[action.payload.room].message.findIndex(
                    (msg) => msg.conference === action.payload.conference
                );
                if (index >= 0) {
                    let conferenceData = tempMessage[action.payload.room].message[index].conferenceData || {};
                    conferenceData.endedAt = new Date().toISOString();
                    conferenceData.status = 'completed';
                    tempMessage[action.payload.room].message[index].conferenceData = conferenceData;
                }
            }
            return {
                ...state,
                messages: tempMessage,
            };
        case ActionTypes.SET_CONFERENCE_JOIN:
            tempMessage = Object.assign({}, state.messages);
            if (tempMessage[action.payload.room]) {
                index = tempMessage[action.payload.room].message.findIndex(
                    (msg) => msg.conference === action.payload.conference
                );
                if (index >= 0) {
                    let conferenceData = tempMessage[action.payload.room].message[index].conferenceData || {};
                    conferenceData.participants.push(action.payload.user);
                    let allParticipants = conferenceData.allParticipants || [];
                    let participantIndex = allParticipants.findIndex(
                        (participant) =>
                            (participant.id || participant._id) === (action.payload.user.id || action.payload.user._id)
                    );
                    if (participantIndex === -1) {
                        allParticipants.push(action.payload.user);
                    }
                    conferenceData.allParticipants = allParticipants;
                    tempMessage[action.payload.room].message[index].conferenceData = conferenceData;
                }
            }
            return {
                ...state,
                messages: tempMessage,
            };
        case ActionTypes.SET_CONFERENCE_LEAVE:
            tempMessage = Object.assign({}, state.messages);
            if (tempMessage[action.payload.room]) {
                index = tempMessage[action.payload.room].message.findIndex(
                    (msg) => msg.conference === action.payload.conference
                );
                if (index >= 0) {
                    let conferenceData = tempMessage[action.payload.room].message[index].conferenceData || {};
                    let participants = conferenceData.participants;
                    conferenceData.participants = participants.filter(
                        (participant) => participant.id !== action.payload.user
                    );
                    tempMessage[action.payload.room].message[index].conferenceData = conferenceData;
                }
            }
            return {
                ...state,
                messages: tempMessage,
            };
        case ActionTypes.MESSAGE_EMOJI:
            tempMessage = Object.assign({}, state.messages);
            if (tempMessage[action.payload.id]) {
                index = tempMessage[action.payload.id].message.findIndex((org) => org.id === action.payload.messageId);
                if (index >= 0) {
                    // let msgEmojiArray = tempMessage[action.payload.id].message[index].emoji;
                    // let reactionIndex = msgEmojiArray.findIndex(item => item.by === action.payload.message.emoji.by)
                }
            }

            return {
                ...state,
                messages: tempMessage,
            };
        case ActionTypes.CLEAR_MESSAGES:
            return {
                ...state,
                messages: {},
            };

        case ActionTypes.SET_SEARCH_MESSAGES:
            return {
                ...state,
                isSearch: true,
                searchMessages: [...action.payload.results],
                totalSearchMessages: action.payload.totalResults,
            };

        case ActionTypes.CLEAR_SEARCH_MESSAGES:
            return {
                ...state,
                isSearch: false,
                searchFilters: {},
            };

        case ActionTypes.SET_SEARCH_FILTER:
            return {
                ...state,
                searchFilters: action.payload,
            };

        case ActionTypes.SET_LOADING_MESSAGE:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.STOP_LOADING_MESSAGE:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.SET_UPLOADING:
            return {
                ...state,
                isUploading: true,
            };
        case ActionTypes.CLEAR_UPLOADING:
            return {
                ...state,
                isUploading: false,
            };
        case ActionTypes.SET_ACCESSING_BOT:
            return {
                ...state,
                botInfo: action.payload,
            };
        case ActionTypes.CLEAR_ACCESSING_BOT:
            return {
                ...state,
                botInfo: {},
            };

        case ActionTypes.SET_LAST_SEEN_MESSAGE:
            tempMessage = Object.assign({}, state.messages);
            if (tempMessage[action.payload.id]) {
                tempMessage[action.payload.id].lastSeen = action.payload.message;
                return {
                    ...state,
                    messages: tempMessage,
                };
            }
            return state;
        case ActionTypes.IS_NEW_MESSAGE:
            return {
                ...state,
                isNewMessage: action.payload,
            };

        default:
            return state;
    }
}
