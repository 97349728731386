//core imports
import React, { useEffect, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useIdleTimer } from 'react-idle-timer';
import ReactGA from 'react-ga';
import * as Sentry from '@sentry/react';

// routes
import NotFound from './components/NotFound/NotFound';
import { PrivateRoute } from './_helpers';

// base style
import './app.scss';

//redex setup
import { connect } from 'react-redux';
import { getUserData, setUserLocation } from './_redux/actions/ActionUser';
import { getAllOrganization, clearMessage } from './_redux/actions/ActionOrganization';
import { LoadingContainer } from './_elements/LoadingContainer';
import { getAllActivity } from './_redux/actions/ActionActivity';

import ChatContainer from './components/Features/Chat/ChatContainer';
import TeamContainer from './components/Features/Teams/TeamContainer';
import ActivityContainer from './components/Features/Activity/ActivityContainer';
import EventsContainer from './components/Features/Events/EventsContainer';
import CourseContainer from './components/Features/Course/CourseContainer';
import { GetAllTeams, GetMyTeams } from './_redux';
import TeamSettings from './components/Features/Teams/TeamSettings/TeamSettings';
import { useLogout } from './components/Features/Common/useLogout';
import RouteChangeTracker from './_helpers/HOC/RouteChangeTracker';
import ConferenceInvitation from './components/ConferenceInvitation/ConferenceInvitation';
import ConferenceContainer from './components/Features/Conference/ConferenceContainer';
import useMobileRedirecter from './_helpers/hooks/useMobileRedirecter';
import EventChannelContainer from './components/Admin/Tasks/eventchannel/EventChannelContainer';
import Tags from './components/Admin/Tags';

//components
const Home = lazy(() => import('./components/Home/Home'));
const Register = lazy(() => import('./components/Auth/Register'));
const Login = lazy(() => import('./components/Auth/Login'));
const ReportIssues = lazy(() => import('./components/Features/ReportIssues/ReportIssues'));
const PublicForms = lazy(() => import('./components/Features/PublicForms'));
const ResetPassword = lazy(() => import('./components/Auth/ResetPassword'));
const ForceResetPassword = lazy(() => import('./components/Auth/ForceResetPassword'));
const ForceUsername = lazy(() => import('./components/Auth/ForceUsername'));
const ForcePhoneNumber = lazy(() => import('./components/Auth/ForcePhoneNumber'));
const CreateYourFirstTeam = lazy(() => import('./components/Features/Teams/CreateYourFirstTeam'));
const VerifyEmail = lazy(() => import('./components/Auth/VerifyEmail/VerifyEmail'));
const Verification = lazy(() => import('./components/Auth/VerifyEmail/Verification'));
const Admin = lazy(() => import('./components/Admin/Admin'));

const App = ({
    setUserLocation,
    getUserData,
    CurrUser,
    getAllOrganization,
    getAllActivity,
    message,
    clearMessage,
    GetAllTeams,
    GetMyTeams,
    organizations,
    conferenceInviation,
}) => {
    const { logoutFunction } = useLogout();
    const GA_TRACKINGID = process.env.REACT_APP_GOOGLE_ANALYTICS;
    useMobileRedirecter();
    useEffect(() => {
        if (GA_TRACKINGID) {
            ReactGA.initialize(GA_TRACKINGID);
        }
    }, [GA_TRACKINGID]);

    useEffect(() => {
        let location = window.location;
        if (process.env.NODE_ENV !== 'development') {
            if (location.protocol === 'http:') {
                console.log('you are accessing us via an insecure protocol (HTTP). Redirecting you to HTTPS.');
                location.href = location.href.replace('http:', 'https:');
            } else {
                if (location.protocol === 'https:') {
                    console.log('you are accessing us via our secure HTTPS protocol.');
                }
            }
        }
    }, []);

    useEffect(() => {
        if (CurrUser.isLogged) {
            getUserData();
            setUserLocation();
            getAllOrganization();
            getAllActivity();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [CurrUser.isLogged, getUserData, setUserLocation, getAllOrganization, getAllActivity]);
    useEffect(() => {
        if (organizations?.length) {
            GetAllTeams('5f3569c2647fb5000c019e42');
            GetMyTeams('5f3569c2647fb5000c019e42');
        }
    }, [organizations]);

    useEffect(() => {
        if (message) {
            if (message.error === false) {
                toast.success(message.message);
                clearMessage();
            } else if (message) {
                toast.error(message.message);
                clearMessage();
            }
        }
    }, [CurrUser, message, clearMessage]);

    //costume logger for dev only
    // to log do global.log(hello)
    const log = (...msgs) => {
        if (process.env.NODE_ENV === 'development') console.log(...msgs);
    };
    global.log = log;
    const handleOnIdle = (event) => {
        // console.log("CALLED IS IDLE LOGGOUT")
        const user = window.localStorage.getItem('user');
        if (user) {
            const isInACall = window.location.pathname.includes('meet');
            if (isInACall) {
                return;
            }
            console.log('You have been sessioned out due to inactivity,', getLastActiveTime());
            window.localStorage.setItem('inactivity', 'true');
            logoutFunction();
        }
    };

    const { getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * 60,
        onIdle: handleOnIdle,
        debounce: 500,
        crossTab: {
            type: undefined,
            channelName: 'idle-timer',
            fallbackInterval: 1000 * 60 * 60,
            responseTime: 100,
            removeTimeout: 1000 * 60 * 60,
            emitOnAllTabs: false,
        },
    });

    useEffect(() => {
        const checkIfLoggedOut = (event) => {
            if (event.key === 'user') {
                if (event.newValue === null) {
                    console.log('You have been sessioned out due to inactivity,');
                    window.localStorage.setItem('inactivity', 'true');
                    logoutFunction();
                }
            }
        };
        window.addEventListener('storage', checkIfLoggedOut);

        return () => {
            window.removeEventListener('storage', checkIfLoggedOut);
        };
    }, [logoutFunction]);

    return (
        <React.Fragment>
            <Router>
                <RouteChangeTracker />
                <Suspense fallback={<LoadingContainer text="loading ..." />}>
                    <Switch>
                        <PrivateRoute exact path="/teams" component={TeamContainer} />
                        <PrivateRoute exact path="/event-channel" component={EventChannelContainer} />
                        <PrivateRoute exact path="/event-channel/:subChannelId" component={EventChannelContainer} />
                        <PrivateRoute exact path="/teams/:roomId" component={TeamContainer} />
                        <PrivateRoute exact path="/teams/:teamId/settings" verifyUser={true} component={TeamSettings} />
                        <PrivateRoute
                            exact
                            path="/teams/:teamId/channels/:channelId/settings"
                            verifyUser={true}
                            component={TeamSettings}
                        />
                        <PrivateRoute exact path="/chat" component={ChatContainer} />
                        <PrivateRoute exact path="/chat/:userId" component={ChatContainer} />
                        <PrivateRoute exact path="/activity" component={ActivityContainer} />
                        <PrivateRoute exact path="/events" component={EventsContainer} />
                        <PrivateRoute exact path="/events/:channelId" component={EventsContainer} />
                        <PrivateRoute exact path="/courses" component={CourseContainer} />
                        <PrivateRoute exact path="/meet/:meetId" component={ConferenceContainer} />
                        <Route path="/" exact component={Home} />
                        <Route path="/register" exact component={Register} />
                        <Route path="/login" exact component={Login} />
                        <Route path="/report_issues" exact component={ReportIssues} />
                        <Route path="/reset-password" exact component={ResetPassword} />
                        <Route path="/force-password-change" exact component={ForceResetPassword} />
                        <Route path="/force-username" exact component={ForceUsername} />
                        <Route path="/force-phonenumber" exact component={ForcePhoneNumber} />
                        <Route path="/create-your-team" exact component={CreateYourFirstTeam} />
                        <Route path="/forms/:sharedFormId" exact component={PublicForms} />
                        <Route exact path="/verify-email" component={VerifyEmail} />
                        <PrivateRoute exact path="/verification/:email" component={Verification} />
                        <PrivateRoute exact path="/admin" roles="admin" component={Admin} />
                        {/* <Route exact path="/not-found" component={NotFound} /> */}
                        <Route path="*" component={NotFound} />
                    </Switch>
                </Suspense>

                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                {conferenceInviation && <ConferenceInvitation conferenceInviation={conferenceInviation} />}
            </Router>
        </React.Fragment>
    );
};

App.prototype = {
    CurrUser: PropTypes.object.isRequired,
    getUserData: PropTypes.func.isRequired,
    setUserLocation: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    CurrUser: state.user,
    organizations: state.organizations.organizations,
    message: state.ui.message,
    conferenceInviation: state.conference.conferenceCallInvitation,
});

const AppWithSentry = Sentry.withErrorBoundary(
    connect(mapStateToProps, {
        getUserData,
        setUserLocation,
        getAllOrganization,
        getAllActivity,
        clearMessage,
        GetAllTeams,
        GetMyTeams,
    })(App),
    { fallback: <p>An error has occurred.</p> }
);

export default AppWithSentry;
