import { css } from 'twin.macro';
import React, { useState, Fragment, useRef, useMemo } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FacebookSelector, FacebookCounter } from '@charkour/react-reactions';

//Helpers
import { fetchWrapper, SanitizeHTML, isJson, Permission, checkPermission } from '../../../_helpers';
import { useOutsideClickListener } from '../../../_helpers/hooks/useOutsideClickListener';

//components
import MessageTime from '../Common/MessageTime';
import FileMessage from '../Common/FileMessage';
import ChatInput from '../Common/ChatInput';
import { GoogleMapPopUP } from '../Common/GoogleMapPopUP';
import ReplyFile from '../Common/ReplyFile';
import SeenByDiv from './SeenByDiv';
import PermissionMessage from './PermissionMessage';

//Styles
import { msgContainer } from './styles/MessageStyles';

//icons
import { BsThreeDotsVertical } from 'react-icons/bs';
import { HiOutlineEmojiHappy } from 'react-icons/hi';
import ImagesMessage from '../Common/Messages/ImagesMessage';
import FilesMessage from '../Common/Messages/FilesMessage';
import { FaPhotoVideo } from 'react-icons/fa';

const SenderMessage = ({
    rId,
    setReply,
    position,
    refs,
    teamData,
    handleClick,
    handleMessageClick,
    m,
    isForm,
    currUser,
    sendEditedMessage,
    handleDeleteMessage,
    roomType,
    userRole,
    showMessageReactions,
    subChannelId,
    subChannelType,
    roomData,
}) => {
    const { id, file, images, files, type, dateAndTime, seenBy, isEdited, reaction, yourReaction, visibleTo } = m;
    const canDownload = useMemo(() => {
        if (currUser?.role === 'admin') {
            return true;
        } else if (['owner', 'teamManager'].includes(teamData?.role)) {
            return true;
        } else {
            return false;
        }
    }, [currUser, teamData]);
    let messageFile;
    if (file) {
        messageFile = 'file';
    } else if (images && images?.length) {
        messageFile = 'images';
    } else if (files && files?.length) {
        messageFile = 'files';
    }

    const [showActions, setShowActions] = useState(false);
    const [msgEdited, setMsgEdited] = useState(isEdited || false);
    const mData = m.data;
    const [edit, setEdit] = useState('');
    const [showReactions, setShowReactions] = useState(false);

    const emojiWrapperRef = useRef(null);
    useOutsideClickListener(emojiWrapperRef, () => {
        setShowReactions(false);
    });

    const [reactionCounter, setReactionCounter] = useState(() => {
        if (!reaction) return [];

        let newReaction = reaction?.map((react) => ({
            emoji: react,
        }));
        let myReactionIndex = newReaction.findIndex((reaction) => reaction?.emoji === yourReaction);
        if (myReactionIndex >= 0) {
            let tempReaction = [...newReaction];
            tempReaction.splice(myReactionIndex, 1, { emoji: yourReaction, by: currUser.name });
            return tempReaction;
        } else {
            return newReaction;
        }
    });

    const setReplyMessage = (reply, name) => {
        const teampReply = { ...reply };
        if (teampReply.file || teampReply?.files?.length || teampReply?.images?.length) {
            teampReply.name = name;
            try {
                teampReply.data = teampReply.file || teampReply?.images[0] || teampReply?.files[0];
            } catch (err) {
                teampReply.data = 'shared Files';
            }
            setReply(teampReply);
        } else {
            const { text } = isJson(teampReply.data) ? JSON.parse(teampReply.data) : { text: teampReply.data };
            if (text) {
                teampReply.data = text;
                teampReply.name = name;
                setReply(teampReply);
            }
        }
    };

    let displayMessage, lat, lng, displayReply, displayReplyId, displayReplyUser, replyType;
    try {
        const { text, reply, location } = JSON.parse(mData);
        displayMessage = text;
        if (reply) {
            displayReplyId = reply.id;
            displayReply = reply.reply;
            displayReplyUser = reply.replyUser;
            replyType = reply.type;
        }

        if (location) {
            lat = location.latitude;
            lng = location.longitude;
        }
    } catch (e) {
        displayMessage = mData;
        lat = null;
        lng = null;
    }

    const handleReaction = async (emoji) => {
        setShowReactions(false);
        // setting the reaction counter
        let myReactionIndex = reactionCounter.findIndex((reaction) => reaction?.by === currUser.name);
        if (subChannelId) {
            if (myReactionIndex >= 0) {
                if (reactionCounter[myReactionIndex].emoji === emoji) {
                    // remove reaction
                    let newReactionCounter = [...reactionCounter];
                    newReactionCounter.splice(myReactionIndex, 1);
                    setReactionCounter(newReactionCounter);
                    await fetchWrapper.put(`subchannels/${subChannelId}/${id}/reactions?remove=true`, {
                        reaction: emoji,
                    });
                } else {
                    let newEmoji = {
                        emoji: emoji,
                        by: currUser.name,
                    };
                    let newReactionCounter = [...reactionCounter];
                    newReactionCounter.splice(myReactionIndex, 1, newEmoji);
                    setReactionCounter(newReactionCounter);
                    await fetchWrapper.put(`subchannels/${subChannelId}/${id}/reactions`, { reaction: emoji });
                }
            } else {
                setReactionCounter([
                    ...reactionCounter,
                    {
                        emoji: emoji,
                        by: currUser.name,
                    },
                ]);
                await fetchWrapper.put(`subchannels/${subChannelId}/${id}/reactions`, { reaction: emoji });
            }
        } else {
            if (myReactionIndex >= 0) {
                if (reactionCounter[myReactionIndex].emoji === emoji) {
                    // remove reaction
                    let newReactionCounter = [...reactionCounter];
                    newReactionCounter.splice(myReactionIndex, 1);
                    setReactionCounter(newReactionCounter);
                    await fetchWrapper.put(`chatrooms/${rId}/${id}/reactions?remove=true`, { reaction: emoji });
                } else {
                    let newEmoji = {
                        emoji: emoji,
                        by: currUser.name,
                    };
                    let newReactionCounter = [...reactionCounter];
                    newReactionCounter.splice(myReactionIndex, 1, newEmoji);
                    setReactionCounter(newReactionCounter);
                    await fetchWrapper.put(`chatrooms/${rId}/${id}/reactions`, { reaction: emoji });
                }
            } else {
                setReactionCounter([
                    ...reactionCounter,
                    {
                        emoji: emoji,
                        by: currUser.name,
                    },
                ]);
                await fetchWrapper.put(`chatrooms/${rId}/${id}/reactions`, { reaction: emoji });
            }
        }
    };

    return (
        <Fragment>
            <div key={id} css={msgContainer}>
                <div className="messageContainer justifyEnd">
                    <div className="msg px-5 py-1 d-flex">
                        <div className="d-flex">
                            <Dropdown className="dropper">
                                <Dropdown.Toggle id="dropdown-basic" className="dropdown p-1">
                                    <div className="dropcss-sender">
                                        <BsThreeDotsVertical color="#fff" fontSize={20} />
                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu">
                                    <Dropdown.Item onClick={() => setReplyMessage(m, { name: currUser.name })}>
                                        {edit !== m.id && <span>Reply</span>}
                                    </Dropdown.Item>
                                    {roomType && (roomType === 'private' || roomType === 'public') && (
                                        <Permission
                                            role={currUser.role}
                                            perform="edit:messagePermission"
                                            yes={() => (
                                                <PermissionMessage
                                                    message={m}
                                                    rId={rId}
                                                    editId={edit}
                                                    userRole={userRole}
                                                    teamData={teamData}
                                                    currUser={currUser}
                                                />
                                            )}
                                            no={() => {
                                                let userTeamRole = teamData?.role;
                                                let isTeamRoleAuthorized = checkPermission(
                                                    userTeamRole,
                                                    'edit:messagePermission'
                                                );
                                                let userChannel = currUser.roomList
                                                    .filter((room) => room.room)
                                                    .find((room) => room.room.id === rId);
                                                let userChannelRole = (userChannel || {}).role;

                                                let isChannelRoleAuthorized = false;
                                                if (userChannelRole) {
                                                    isChannelRoleAuthorized = checkPermission(
                                                        userChannelRole,
                                                        'edit:messagePermission'
                                                    );
                                                }
                                                let isEditPermissionAllowed =
                                                    isTeamRoleAuthorized || isChannelRoleAuthorized;

                                                if (isEditPermissionAllowed) {
                                                    return (
                                                        <PermissionMessage
                                                            message={m}
                                                            rId={rId}
                                                            editId={edit}
                                                            userRole={userRole}
                                                            teamData={teamData}
                                                            currUser={currUser}
                                                        />
                                                    );
                                                }
                                                return null;
                                            }}
                                        />
                                    )}
                                    {subChannelType && subChannelType === 'private' && (
                                        <Permission
                                            role={currUser.role}
                                            perform="edit:messagePermission"
                                            yes={() => (
                                                <PermissionMessage
                                                    message={m}
                                                    subChannelId={subChannelId}
                                                    editId={edit}
                                                    userRole={userRole}
                                                />
                                            )}
                                            no={() => {
                                                let userRoomRole = roomData?.role;
                                                let isRoomRoleAuthorized = checkPermission(
                                                    userRoomRole,
                                                    'edit:messagePermission'
                                                );
                                                let userSubChannel = currUser.subChannelList
                                                    .filter((subChannel) => subChannel.subChannel)
                                                    .find((subChannel) => subChannel.subChannel.id === subChannelId);
                                                let userSubChannelRole = (userSubChannel || {}).role;

                                                let isSubChannelRoleAuthorized = false;
                                                if (userSubChannelRole) {
                                                    isSubChannelRoleAuthorized = checkPermission(
                                                        userRoomRole,
                                                        'edit:messagePermission'
                                                    );
                                                }
                                                let isEditPermissionAllowed =
                                                    isRoomRoleAuthorized || isSubChannelRoleAuthorized;

                                                if (isEditPermissionAllowed) {
                                                    return (
                                                        <PermissionMessage
                                                            message={m}
                                                            subChannelId={subChannelId}
                                                            editId={edit}
                                                            userRole={userRole}
                                                        />
                                                    );
                                                }
                                                return null;
                                            }}
                                        />
                                    )}

                                    {isForm && isForm.test(m.data) ? null : (
                                        <Dropdown.Item onClick={() => setEdit((prev) => (prev ? '' : m.id))}>
                                            <span>{edit === m.id ? 'Cancel' : 'Edit'}</span>
                                        </Dropdown.Item>
                                    )}

                                    {edit !== m.id && (
                                        <Fragment>
                                            {subChannelId ? (
                                                <Permission
                                                    role={currUser?.role}
                                                    perform="delete:message"
                                                    yes={() => (
                                                        <Dropdown.Item onClick={() => handleDeleteMessage(m.id)}>
                                                            <span>Delete</span>
                                                        </Dropdown.Item>
                                                    )}
                                                    no={() => {
                                                        let userRoomRole = roomData?.role;
                                                        let isRoomRoleAuthorized = checkPermission(
                                                            userRoomRole,
                                                            'delete:message'
                                                        );
                                                        let userSubChannel = currUser.subChannelList
                                                            .filter((subChannel) => subChannel.subChannel)
                                                            .find((subChannel) => subChannel.subChannel.id === rId);
                                                        let userSubChannelRole = { owner: 'user', user: 'owner' }[
                                                            (userSubChannel || {})?.role || 'owner'
                                                        ];

                                                        let isSubChannelRoleAuthorized = false;
                                                        if (userSubChannelRole) {
                                                            isSubChannelRoleAuthorized = checkPermission(
                                                                userSubChannelRole,
                                                                'delete:message'
                                                            );
                                                        }
                                                        let isDeleteAllowed =
                                                            isRoomRoleAuthorized || isSubChannelRoleAuthorized;

                                                        if (isDeleteAllowed) {
                                                            return (
                                                                <Dropdown.Item
                                                                    onClick={() => handleDeleteMessage(m.id)}
                                                                >
                                                                    <span>Delete</span>
                                                                </Dropdown.Item>
                                                            );
                                                        }
                                                        return null;
                                                    }}
                                                />
                                            ) : (
                                                <Permission
                                                    role={currUser?.role}
                                                    perform="delete:message"
                                                    yes={() => (
                                                        <Dropdown.Item onClick={() => handleDeleteMessage(m.id)}>
                                                            <span>Delete</span>
                                                        </Dropdown.Item>
                                                    )}
                                                    no={() => {
                                                        let userTeamRole = teamData?.role;
                                                        let isTeamRoleAuthorized = checkPermission(
                                                            userTeamRole,
                                                            'delete:message'
                                                        );
                                                        let userChannel = currUser.roomList
                                                            .filter((room) => room.room)
                                                            .find((room) => room.room.id === rId);
                                                        let userChannelRole = (userChannel || {}).role;

                                                        let isChannelRoleAuthorized = false;
                                                        if (userChannelRole) {
                                                            isChannelRoleAuthorized = checkPermission(
                                                                userChannelRole,
                                                                'delete:message'
                                                            );
                                                        }
                                                        let isDeleteAllowed =
                                                            isTeamRoleAuthorized || isChannelRoleAuthorized;

                                                        if (isDeleteAllowed) {
                                                            return (
                                                                <Dropdown.Item
                                                                    onClick={() => handleDeleteMessage(m.id)}
                                                                >
                                                                    <span>Delete</span>
                                                                </Dropdown.Item>
                                                            );
                                                        }

                                                        return isForm && !isForm.test(m.data) ? (
                                                            <Dropdown.Item onClick={() => handleDeleteMessage(m.id)}>
                                                                <span>Delete</span>
                                                            </Dropdown.Item>
                                                        ) : null;
                                                    }}
                                                />
                                            )}
                                        </Fragment>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                            {roomType ? (
                                <div
                                    css={css`
                                        position: relative;
                                        &.show {
                                            visibility: visible !important;
                                            opacity: 1;
                                        }
                                    `}
                                    className={`dropper ${showReactions ? 'show' : ''}`}
                                >
                                    <div
                                        css={css`
                                            position: relative;
                                        `}
                                    >
                                        <div ref={emojiWrapperRef}>
                                            <HiOutlineEmojiHappy
                                                css={css`
                                                    cursor: pointer;
                                                `}
                                                color="#50aeb0"
                                                fontSize={35}
                                                onClick={() => setShowReactions(!showReactions)}
                                            />
                                            {showReactions ? (
                                                <div
                                                    css={css`
                                                        position: absolute;
                                                        z-index: 99999;
                                                        top: -265%;
                                                        right: 0;
                                                    `}
                                                >
                                                    <FacebookSelector onSelect={handleReaction} />
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            {subChannelType ? (
                                <div
                                    css={css`
                                        position: relative;
                                        &.show {
                                            visibility: visible !important;
                                            opacity: 1;
                                        }
                                    `}
                                    className={`dropper ${showReactions ? 'show' : ''}`}
                                >
                                    <div
                                        css={css`
                                            position: relative;
                                        `}
                                    >
                                        <div ref={emojiWrapperRef}>
                                            <HiOutlineEmojiHappy
                                                css={css`
                                                    cursor: pointer;
                                                `}
                                                color="#50aeb0"
                                                fontSize={30}
                                                onClick={() => setShowReactions(!showReactions)}
                                            />
                                            {showReactions ? (
                                                <div
                                                    css={css`
                                                        position: absolute;
                                                        z-index: 99999;
                                                        top: -265%;
                                                        right: 0;
                                                    `}
                                                >
                                                    <FacebookSelector onSelect={handleReaction} />
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            <div
                                className="p-2 pointer"
                                css={css`
                                    font-size: 14px;
                                `}
                                ref={refs[id]}
                            >
                                <div className="sent-msg">
                                    {type === 'message' ? (
                                        <div>
                                            {displayReply && (
                                                <div
                                                    className="sent-reply-msg"
                                                    onClick={() => handleClick(displayReplyId)}
                                                >
                                                    <span className="font-weight-bold">{displayReplyUser}</span>
                                                    {replyType === 'message' ? (
                                                        <div className="d-flex small">
                                                            <SanitizeHTML html={displayReply} />
                                                        </div>
                                                    ) : (
                                                        <div className="d-flex justify-content-end text-justify">
                                                            <ReplyFile file={displayReply} />
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                            {msgEdited ? <div className="edited-label"> Edited</div> : null}

                                            {edit === id ? (
                                                <div
                                                    css={css`
                                                        .message-inputarea {
                                                            .input-features {
                                                                .emojiDrop {
                                                                    .dropdown {
                                                                        a {
                                                                            color: #50aeb0 !important;
                                                                        }
                                                                    }
                                                                }
                                                                > a {
                                                                    color: #50aeb0 !important;
                                                                }
                                                            }
                                                        }
                                                    `}
                                                >
                                                    {subChannelId ? (
                                                        <ChatInput
                                                            messageText={displayMessage}
                                                            key={subChannelId}
                                                            id={subChannelId}
                                                            sendMessage={(e, data) => {
                                                                e.preventDefault();
                                                                const { reply } = JSON.parse(mData);
                                                                if (reply) {
                                                                    sendEditedMessage(
                                                                        reply,
                                                                        data,
                                                                        edit,
                                                                        position,
                                                                        subChannelId,
                                                                        msgEdited,
                                                                        visibleTo
                                                                    );
                                                                    setMsgEdited(true);
                                                                } else {
                                                                    sendEditedMessage(
                                                                        null,
                                                                        data,
                                                                        edit,
                                                                        position,
                                                                        subChannelId,
                                                                        msgEdited,
                                                                        visibleTo
                                                                    );
                                                                    setMsgEdited(true);
                                                                }
                                                                setEdit(false);
                                                            }}
                                                            type="team"
                                                            editMode
                                                        />
                                                    ) : (
                                                        <ChatInput
                                                            messageText={displayMessage}
                                                            key={rId}
                                                            id={rId}
                                                            sendMessage={(e, data) => {
                                                                e.preventDefault();
                                                                const { reply } = JSON.parse(mData);
                                                                if (reply) {
                                                                    sendEditedMessage(
                                                                        reply,
                                                                        data,
                                                                        edit,
                                                                        position,
                                                                        rId,
                                                                        msgEdited,
                                                                        visibleTo
                                                                    );
                                                                    setMsgEdited(true);
                                                                } else {
                                                                    sendEditedMessage(
                                                                        null,
                                                                        data,
                                                                        edit,
                                                                        position,
                                                                        rId,
                                                                        msgEdited,
                                                                        visibleTo
                                                                    );
                                                                    setMsgEdited(true);
                                                                }
                                                                setEdit(false);
                                                            }}
                                                            type="team"
                                                            editMode
                                                        />
                                                    )}
                                                </div>
                                            ) : (
                                                <div
                                                    className="sender-message d-flex justify-content-end text-justify"
                                                    css={css`
                                                        padding: 5px 10px !important;
                                                    `}
                                                    onClick={() => {
                                                        if (isForm) {
                                                            handleMessageClick();
                                                        }
                                                        setShowActions(!showActions);
                                                    }}
                                                >
                                                    {displayMessage === '' && lat && lng ? (
                                                        <span>
                                                            shared a location: <GoogleMapPopUP lat={lat} lng={lng} />
                                                        </span>
                                                    ) : (
                                                        <SanitizeHTML html={displayMessage} />
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        // Messages with Medias
                                        <Fragment>
                                            {msgEdited ? <div className="edited-label"> Edited</div> : null}

                                            {edit === id ? (
                                                <div
                                                    css={css`
                                                        .message-inputarea {
                                                            .input-features {
                                                                .emojiDrop {
                                                                    .dropdown {
                                                                        a {
                                                                            color: #50aeb0 !important;
                                                                        }
                                                                    }
                                                                }
                                                                > a {
                                                                    color: #50aeb0 !important;
                                                                }
                                                            }
                                                        }
                                                    `}
                                                >
                                                    {subChannelId ? (
                                                        <ChatInput
                                                            messageText={displayMessage}
                                                            key={subChannelId}
                                                            id={subChannelId}
                                                            sendMessage={(e, data) => {
                                                                e.preventDefault();
                                                                const { reply } = JSON.parse(mData);
                                                                if (reply) {
                                                                    sendEditedMessage(
                                                                        reply,
                                                                        data,
                                                                        edit,
                                                                        position,
                                                                        subChannelId,
                                                                        msgEdited,
                                                                        visibleTo
                                                                    );
                                                                    setMsgEdited(true);
                                                                } else {
                                                                    sendEditedMessage(
                                                                        null,
                                                                        data,
                                                                        edit,
                                                                        position,
                                                                        subChannelId,
                                                                        msgEdited,
                                                                        visibleTo
                                                                    );
                                                                    setMsgEdited(true);
                                                                }
                                                                setEdit(false);
                                                            }}
                                                            type="team"
                                                            editMode
                                                        />
                                                    ) : (
                                                        <ChatInput
                                                            messageText={displayMessage}
                                                            key={rId}
                                                            id={rId}
                                                            sendMessage={(e, data) => {
                                                                e.preventDefault();
                                                                const { reply } = JSON.parse(mData);
                                                                if (reply) {
                                                                    sendEditedMessage(
                                                                        reply,
                                                                        data,
                                                                        edit,
                                                                        position,
                                                                        rId,
                                                                        msgEdited,
                                                                        visibleTo
                                                                    );
                                                                    setMsgEdited(true);
                                                                } else {
                                                                    sendEditedMessage(
                                                                        null,
                                                                        data,
                                                                        edit,
                                                                        position,
                                                                        rId,
                                                                        msgEdited,
                                                                        visibleTo
                                                                    );
                                                                    setMsgEdited(true);
                                                                }
                                                                setEdit(false);
                                                            }}
                                                            type="team"
                                                            editMode
                                                        />
                                                    )}
                                                    <div
                                                        css={css`
                                                            min-width: 350px;
                                                            display: flex;
                                                            align-items: center;
                                                            font-weight: 700;
                                                            padding: 0 2rem;
                                                        `}
                                                    >
                                                        <FaPhotoVideo fontSize="48" />
                                                        <span
                                                            css={css`
                                                                margin-left: 1rem;
                                                            `}
                                                        >
                                                            Shared Media
                                                        </span>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div
                                                    className="d-flex justify-content-end text-justify flex-column"
                                                    css={css`
                                                        padding: 0.5rem;
                                                        .pdftext,
                                                        .fileName {
                                                            color: white !important;
                                                        }
                                                    `}
                                                    onClick={() => {
                                                        setShowActions(!showActions);
                                                    }}
                                                >
                                                    {messageFile === 'file' ? (
                                                        <FileMessage
                                                            file={file}
                                                            displayMessage={displayMessage}
                                                            // fileName={file.fileName}
                                                            from="current-user"
                                                            css={css`
                                                                color: white !important;
                                                            `}
                                                            documentSettings={canDownload}
                                                        />
                                                    ) : messageFile === 'images' ? (
                                                        <ImagesMessage
                                                            images={images}
                                                            displayMessage={displayMessage}
                                                            from="current-user"
                                                            css={css`
                                                                color: white !important;
                                                            `}
                                                            teamDetails={canDownload}
                                                        />
                                                    ) : messageFile === 'files' ? (
                                                        <FilesMessage
                                                            files={files}
                                                            displayMessage={displayMessage}
                                                            // fileName={file.fileName}
                                                            from="current-user"
                                                            css={css`
                                                                color: white !important;
                                                            `}
                                                            canDownload={canDownload}
                                                        />
                                                    ) : null}
                                                </div>
                                            )}
                                        </Fragment>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div
                            css={css`
                                visibility: ${reactionCounter.length ? 'visible' : 'hidden'};
                                display: flex;
                                justify-content: flex-end;
                                z-index: 10;
                                > div {
                                    align-items: center;
                                    background: white;
                                    border: 1px solid #d8d8d8;
                                    padding: 0 0.5rem;
                                    border-radius: 50px;
                                    bottom: -0.6rem;
                                    position: absolute;
                                }
                            `}
                            onClick={() => showMessageReactions(id)}
                        >
                            <FacebookCounter user={currUser?.name} counters={reactionCounter} />
                        </div>
                        {showActions && (
                            <div className="d-flex justify-content-end lowerDetails">
                                <span className="px-2 text-right text-muted pointer">
                                    {lat && lng && <GoogleMapPopUP lat={lat} lng={lng} />}
                                </span>

                                <span className="px-2 text-right text-muted text-uppercase">
                                    <MessageTime dateAndTime={dateAndTime} />
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {seenBy?.length > 0 && <SeenByDiv seenBy={seenBy} messageType="self" />}
        </Fragment>
    );
};
export default SenderMessage;
