import { Fragment } from 'react';
import { CreateEventStyles } from './Styles/PreviewModalStyles';
import PreviewEventModalForm from './PreviewEventModalForm';

function PreviewEventModal({ data, handleClose, handleUpdateTask }) {
    return (
        <Fragment>
            <CreateEventStyles size="lg" centered show={true} onHide={handleClose}>
                <PreviewEventModalForm data={data} handleClose={handleClose} handleUpdateTask={handleUpdateTask} />
            </CreateEventStyles>
        </Fragment>
    );
}

export default PreviewEventModal;
