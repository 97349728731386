// Dialog.js
import React, { useEffect, useState } from 'react';
import { css } from 'twin.macro';
import logo from '../../../assets/logos/logo.svg';
const customDialogStyles = css`
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    background: #fafafa;
    border: 1px solid #ccc;
    border-radius: 5px;
    z-index: 1000;
    width: 80%;
    max-height: 262px;
    min-width: 350px;
    box-sizing: content-box;
    border-radius: 8px;
    overflow-y: auto;

    bottom: 100%;
    margin-bottom: 10px;
`;

const ulStyle = css`
margin: 0;
li {
    line-height: 1.4;
    cursor:pointer;
    align-items:center;
    list-style-type: none;
}
.list-style{
    padding:5px;
    border-radius:8px;
    transition: background-color 0.3s, color 0.3s;
}

.list-item-active{
    background-color: #50aeb0;  
    color: white; 
}
.list-item-inactive{
    background-color: #fafafa;  
    color: black; 
}
.ai-description{
    margin-left:30px;
}
}
`;
export const DropdownCommands = [
    // {
    //     name: "Summarizer",
    //     image: "https://a.slack-edge.com/4f28/img/slack_logo_mark.svg",
    //     description: "Summarize text",
    //     command: "/summarize"
    // },
    {
        name: 'Quinn',
        image: 'https://res.cloudinary.com/intelladapt/image/upload/c_fill,f_auto,q_auto,w_56/t7kzeji5mbnbzthvgjh1.png',
        description: 'Ask question with Quinn ( eg. /quinn What is ...? )',
        command: '/quinn',
        activity: 'Optobot is thinking...',
    },
    {
        name: 'SMS',
        image: 'https://optonome-staging.s3.amazonaws.com/chatroom/62f30a89997e8e0018b16bb9/674e70bcec3d926986b2a35d_1733193916816_sms.png',
        description: 'Send SMS ( eg. /sms  +17182222222 Hello )',
        command: '/sms',
    },
    {
        name: 'Form',
        command: '/form',
        ui: true,
    },
    {
        name: 'Calendar',
        command: '/calendar',
        ui: true,
    },
];
const CommandDropdown = ({ type, onCommandSelected, message, commandList, setCommandList }) => {
    const [highlightedIndex, setHighlightedIndex] = useState(0);
    const handleMouseEnter = (index) => {
        setHighlightedIndex(index);
    };

    const handleMouseLeave = () => {
        setHighlightedIndex(0);
    };

    const handleItemClick = (item) => {
        onCommandSelected(item);
    };

    useEffect(() => {
        const [messageCommand] = message.split('\n')[0].trim().toLowerCase().split(' ');
        const filteredList = DropdownCommands.filter(
            (item) =>
                item.command.toLowerCase().includes(messageCommand) && item.command.toLowerCase() !== messageCommand
        );
        setCommandList(filteredList);
    }, [message]);
    return (
        type === 'team' &&
        commandList.length > 0 &&
        message.startsWith('/') && (
            <div css={customDialogStyles}>
                <ul css={ulStyle}>
                    {commandList.map((item, index) => (
                        <li
                            key={item.command}
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={handleMouseLeave}
                            onClick={() => handleItemClick(item)}
                            className={`${highlightedIndex === index ? 'list-item-active' : 'list-item-inactive'} list-style`}
                        >
                            <div>
                                <img
                                    src={item?.image || logo}
                                    alt="logo"
                                    style={{ height: '20px', width: '20px' }}
                                    loading="lazy"
                                />
                                <span
                                    css={css`
                                        margin-left: 10px;
                                        font-weight: bold;
                                    `}
                                >
                                    {item?.command}
                                </span>
                            </div>
                            <p className="ai-description">{item?.description} </p>
                        </li>
                    ))}
                </ul>
            </div>
        )
    );
};

export default CommandDropdown;
