import { css } from 'twin.macro';
import { Button, FormLabel, InputGroup, Modal } from 'react-bootstrap';
import { Fragment, useState, useCallback, useEffect, useRef } from 'react';
import {
    fetchWrapper,
    getDateTimeFromDateTime,
    getTeamIdFromURL,
    toastWrapper,
    getChannelIdFromSettingsURL,
} from '../../../../_helpers';
import Select from 'react-select';

import { DocumentsStyles } from './styles/DocumentsStyled';

//assets
import { AiOutlineMinusCircle } from 'react-icons/ai';
import { VscLoading } from 'react-icons/vsc';
import FormSvg from '../../../../assets/forms.svg';
import { FormPackageTable, ScrollContainer, spin } from './TeamSettingFormPackage';
import DateTimePicker from 'react-datetime-picker/dist/DateTimePicker';
import dayjs from 'dayjs';
import { FaEye } from 'react-icons/fa';
import SubmittedFormsView from '../../../SubmittedFormsView/SubmittedFormsView';
import Avatar from '../../../Admin/reusableComponents/Avatar';
import { ViewUserForm } from '../../../Admin/Form/SubmittedForm';
import FormContributionHistory from '../../../Admin/Form/FormContributionHistory';
import Pagination from '../../../Admin/Pagination';
import SortedTableHeaderIcon from '../../../Admin/SortedTableHeaderIcon';
import { FormPdf } from '../../../../_elements/FormPdf';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import ChannelSelectFilter from '../../../Admin/ChannelSelectFilter';
import TagsModal from '../../Common/Tags/TagsModal';
import { TagPreviewWithContainer, AddTagsTooltip } from '../../Common/Tags/TagsPreview';
import TagSelectFilter from '../../Common/Tags/TagSelectFilter';
import AccumulatedView from './SubmittedForms/AccumulatedView';
import { MdOutlineFileDownload } from 'react-icons/md';

export const ModalCSS = css`
    .modal-header {
        color: white;
        background: #50aeb0;
        button {
            color: white;
        }
        h4 {
            font-size: 2.4rem;
        }
    }
    .modal-body {
        .input-group,
        .search-container {
            margin-bottom: 1rem;
        }
        .form-label,
        .search-title {
            font-weight: bold;
            color: #242424;
        }
        .react-datetime-picker {
            width: 250px;
            .react-datetime-picker__wrapper {
                border: thin solid #cdcdcd;
                padding: 0.2rem 0.5rem;
                border-radius: 3px;
            }
        }
    }
    .tab-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: 1px solid #dee2e6 !important;
        border-top: none !important;
        padding: 2rem 1rem !important;
        min-height: 140px;
    }
`;

const submissionTypes = [
    {
        label: 'Draft Completed Forms',
        value: 'completed',
    },
    {
        label: 'Draft Pending Forms',
        value: 'draft',
    },
    {
        label: 'URL Submissions',
        value: 'urlSubmission',
    },
];

const TeamSettingsGroupForms = ({ search, channelDetails }) => {
    const teamId = getTeamIdFromURL();
    let formCounter = 0;
    const [sortBy, setSortBy] = useState({
        key: 'createdAt',
        direction: 'desc',
    });
    const scrollRef = useRef(null);

    const [form, setForm] = useState(false);
    const [endDate, setEndDate] = useState();
    const [member, setMember] = useState(null);
    const [members, setMembers] = useState([]);
    const [startDate, setStartDate] = useState();
    const [channel, setChannel] = useState(channelDetails?.id ? channelDetails : null);
    const [filteredForms, setFilteredForms] = useState([]);
    const [loadingFiles, setLoadingFiles] = useState(false);
    const [totalForms, setTotalForms] = useState(11);
    const [currentPage, setCurrentPage] = useState(1);
    const [formNames, setFormNames] = useState([]);
    const [formName, setFormName] = useState([]);
    const [isDownloading, setIsDownloading] = useState(false);
    const [searchTags, setSearchTags] = useState([]);
    const [showTagsModal, setShowTagsModal] = useState(false);
    const handleShowTagsModal = (id) => setShowTagsModal(id);
    const handleCloseTagsModal = () => setShowTagsModal(false);
    const channelIdFromUrl = getChannelIdFromSettingsURL();
    const [submissionType, setSubmissionType] = useState(null);
    const updateTagsInSubmittedForms = (updatedFormResponse) => {
        const updatedForms = filteredForms.map((form) =>
            (form?._id || form?.id) === (updatedFormResponse?._id || updatedFormResponse?.id)
                ? { ...form, ...updatedFormResponse }
                : form
        );
        setFilteredForms(updatedForms);
    };

    const generatePdfAndDownloadZip = async (singleFile = true) => {
        setIsDownloading(true);
        try {
            const response = await fetchWrapper.get(
                `/userFormData?sortBy=${sortBy.key}:${sortBy.direction}&limit=3000${
                    channel?.value ? `&chatroom=${channel.value}` : `&team=${teamId || ''}`
                }&user=${member?.value || ''}&startDate=${startDate || ''}&endDate=${endDate || ''}&form=${
                    formName?.value || ''
                }`
            );

            const userForms = response.results;

            // console.log("DOWNLOADING FORMS => ", userForms)

            const pdfName = `${formName?.label || ''}${member?.label ? '_' + member.label : ''}${
                channel?.label ? '_' + channel.label : ''
            }${startDate ? '_' + new Date(startDate).toLocaleDateString() : ''}${
                endDate ? '_' + new Date(endDate).toLocaleDateString() : ''
            }.pdf`;

            if (singleFile) {
                const blob = await FormPdf(userForms, 'combined');
                return FileSaver.saveAs(blob, pdfName);
            }

            const formPdfBlobs = await Promise.all(
                userForms.map(async (userForm) => {
                    return await FormPdf(userForm);
                })
            );

            const zip = JSZip();
            formPdfBlobs.forEach((pdfBlob, i) => {
                zip.file(
                    `${getDateTimeFromDateTime(userForms[i].updatedAt)}-${userForms[i].userData.name}-${
                        userForms[i].formData.name
                    }.pdf`,
                    pdfBlob
                );
            });
            zip.generateAsync({ type: 'blob' }).then((zipFile) => {
                const currentDate = new Date().getTime();
                const fileName = `Bulk-${currentDate}.zip`;
                return FileSaver.saveAs(zipFile, fileName);
            });
        } catch (error) {
            console.log('ERROR IN PDF GENERATION', error);
        } finally {
            setIsDownloading(false);
        }
    };

    const onScroll = () => {
        if (scrollRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
            if (scrollTop + clientHeight === scrollHeight) {
                console.log('reached bottom');
            }
        }
    };

    const onPageChanged = ({ currentPage }) => {
        if (currentPage < 1) {
            currentPage = 1;
        }
        setCurrentPage(currentPage);
    };
    const handleEndDateChange = (date) => {
        if (!date) {
            setEndDate(null);
            return;
        }
        setEndDate(date.toISOString());
    };

    const handleStartDateChange = (date) => {
        if (!date) {
            setStartDate(null);
            return;
        }
        setStartDate(date.toISOString());
    };

    const handleEndDateClear = () => {
        setEndDate(null);
    };
    const handleStartDateClear = () => {
        setStartDate(null);
    };

    const handleSubmissionType = (e) => {
        setSubmissionType(e || null);
    };

    const getSubmittedUserForms = useCallback(
        async (props = {}) => {
            setLoadingFiles(true);
            try {
                let url = `userFormData?sortBy=${sortBy.key}:${sortBy.direction}&limit=10&page=${currentPage}`;
                if (props.form) {
                    url += `&form=${props.form}`;
                }
                if (props.formName) {
                    url += `&name=${props.formName}`;
                }
                if (props.userId) {
                    url += `&user=${props.userId}`;
                }
                if (props.channel || channelIdFromUrl) {
                    url += `&chatroom=${props.channel || channelIdFromUrl}`;
                } else if (teamId) {
                    url += `&team=${teamId}`;
                }
                if (props.startDate) {
                    url += `&startDate=${props.startDate}`;
                }
                if (props.endDate) {
                    url += `&endDate=${props.endDate}`;
                }
                if (props?.searchTags?.length) {
                    url += `&tags=${props?.searchTags}`;
                }
                if (props?.submissionType) {
                    if (props?.submissionType === 'urlSubmission') {
                        url += `&type=normal`;
                        url += `&urlSubmission=${true}`;
                    } else {
                        url += `&submissionType=${props?.submissionType}`;
                        url += `&type=normal`;
                    }
                }
                let response = await fetchWrapper.get(url);
                setFilteredForms(response.results);
                setTotalForms(response.totalResults);
            } catch (error) {
                console.log(error);
                if (error.name === 'AbortError') {
                    return;
                }
                toastWrapper.error(error.message || 'Failed to load submitted forms');
            } finally {
                setLoadingFiles(false);
            }
        },
        [currentPage, sortBy, teamId]
    );

    useEffect(() => {
        let timer = setTimeout(async () => {
            getSubmittedUserForms({
                formName: search,
                channel: channel?.value,
                userId: member?.value,
                startDate: startDate,
                endDate: endDate,
                form: formName?.value,
                searchTags,
                submissionType: submissionType?.value,
            });
        }, 250);

        return () => clearTimeout(timer);
    }, [endDate, formName, search, startDate, member, channel, searchTags, submissionType?.value, sortBy?.direction]);

    const hideFormPreview = () => {
        setForm(false);
    };

    useEffect(() => {
        const getTeamMembers = async () => {
            let FormOptions;
            if (channelIdFromUrl) {
                FormOptions = await fetchWrapper.get(
                    `userFormData/filterOptions/${teamId}?channelId=${channelIdFromUrl}`
                );
            } else {
                FormOptions = await fetchWrapper.get(`userFormData/filterOptions/${teamId}`);
            }
            // `userFormData/filterOptions/${teamId}?channelId=...`
            setMembers(FormOptions?.submittedBy);
            setFormNames(FormOptions?.formName);
        };
        getTeamMembers();
    }, [teamId]);

    const handleChangeMember = (value) => {
        setMember(value);
    };
    const handleClearMember = () => {
        setMember(null);
    };
    const handleFormName = (value) => {
        setFormName(value);
    };
    const handleClearFormName = () => {
        setFormName(null);
    };
    const handleChangeChannel = (value) => {
        setChannel(value);
    };
    const handleSort = (fieldName) => {
        setSortBy({ direction: sortBy.direction === 'asc' ? 'desc' : 'asc', key: fieldName });
    };
    const handleTagSelect = (values) => {
        setSearchTags(values?.map((tag) => tag?.value));
    };

    return (
        <div css={DocumentsStyles}>
            <div
                css={css`
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding: 0 8px;
                `}
                className="row"
            >
                <div>
                    {(formName?.label || member?.label || channel?.label || startDate || endDate) && (
                        <div
                            className=" border rounded mb-4 py-4 "
                            css={css`
                                width: 400px;
                                padding: 0 5px;
                            `}
                        >
                            <b>Search Filter: </b>
                            {formName?.label && (
                                <div className="d-flex justify-content-between">
                                    Form name:
                                    <div>
                                        <b className="text-primary">{formName.label}</b>
                                        <button
                                            className="btn"
                                            onClick={() => {
                                                handleClearFormName({});
                                            }}
                                        >
                                            <AiOutlineMinusCircle fontSize="24px" color="f30e00" />
                                        </button>
                                    </div>
                                </div>
                            )}
                            {member && (
                                <div className="d-flex justify-content-between">
                                    {' '}
                                    Submitted by:
                                    <div>
                                        <b className="text-primary">{member.label}</b>
                                        <button
                                            className="btn"
                                            onClick={() => {
                                                handleClearMember({});
                                            }}
                                        >
                                            <AiOutlineMinusCircle fontSize="24px" color="f30e00" />
                                        </button>
                                    </div>
                                </div>
                            )}
                            {channel && (
                                <div className="d-flex justify-content-between">
                                    {' '}
                                    Channel:
                                    <div>
                                        <b className="text-primary">{channel.label}</b>
                                        <button
                                            className="btn"
                                            onClick={() => {
                                                handleChangeChannel({});
                                            }}
                                        >
                                            <AiOutlineMinusCircle fontSize="24px" color="f30e00" />
                                        </button>
                                    </div>
                                </div>
                            )}
                            <div>
                                {startDate && (
                                    <div className="d-flex justify-content-between">
                                        From:
                                        <div>
                                            <b className="text-primary">
                                                {dayjs(startDate).format('MM-DD-YYYY, h:mm A')}
                                            </b>
                                            <button
                                                className="btn"
                                                onClick={() => {
                                                    handleStartDateClear();
                                                }}
                                            >
                                                <AiOutlineMinusCircle fontSize="24px" color="f30e00" />
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div>
                                {endDate && (
                                    <div className="d-flex justify-content-between">
                                        To:
                                        <div>
                                            <b className="text-primary">
                                                {dayjs(endDate).format('MM-DD-YYYY, h:mm A')}
                                            </b>
                                            <button
                                                className="btn"
                                                onClick={() => {
                                                    handleEndDateClear();
                                                }}
                                            >
                                                <AiOutlineMinusCircle fontSize="24px" color="f30e00" />
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div
                className="row"
                css={css`
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    // justify-content: space-between;
                    width: 100%;
                    align-items: end;
                    z-index: 20;
                    gap: 5px;
                    margin-left: 2px;
                `}
            >
                <div
                    css={css`
                        min-width: 264px;
                    `}
                >
                    <InputGroup className="mb-3 flex-column">
                        <FormLabel className="mr-2 ">Form Name: </FormLabel>
                        <Select
                            value={formName}
                            className="bolder"
                            name="form name"
                            isClearable={true}
                            options={formNames}
                            onChange={handleFormName}
                            placeholder="Enter Form Name"
                        />
                    </InputGroup>
                </div>
                <div
                    css={css`
                        min-width: 264px;
                    `}
                >
                    <InputGroup className="mb-3 flex-column">
                        <FormLabel className="mr-2 ">Submitted by: </FormLabel>
                        <Select
                            value={member}
                            className="bolder"
                            name="member"
                            isClearable={true}
                            options={members}
                            onChange={handleChangeMember}
                            placeholder="Enter Member Name"
                        />
                    </InputGroup>
                </div>
                <div
                    css={css`
                        min-width: 264px;
                    `}
                >
                    <InputGroup className="mb-3 flex-column">
                        <FormLabel className="mr-2 ">Channel: </FormLabel>
                        <ChannelSelectFilter
                            disabled={!!channelIdFromUrl}
                            selectedOption={channelDetails?.id ? channelDetails : channel}
                            selectedRoomOption={channel}
                            onRoomSelect={handleChangeChannel}
                            placeholder="Enter Channel Name"
                            className="bolder"
                            css={css`
                                width: 264px;
                            `}
                        />
                    </InputGroup>
                </div>
                <div
                    css={css`
                        min-width: 264px;
                    `}
                >
                    <InputGroup className="mb-3 flex-column">
                        <FormLabel className=" mr-2">From: </FormLabel>
                        <DateTimePicker
                            onChange={handleStartDateChange}
                            value={startDate ? new Date(startDate) : startDate}
                            format="MM-dd-y hh:mm a"
                            disableClock={true}
                            calendarType={'US'}
                        />
                    </InputGroup>
                </div>
                <div
                    css={css`
                        min-width: 264px;
                    `}
                >
                    <InputGroup className="mb-3 flex-column">
                        <FormLabel className="mr-2 ">To: </FormLabel>
                        <DateTimePicker
                            onChange={handleEndDateChange}
                            value={endDate ? new Date(endDate) : endDate}
                            format="MM-dd-y hh:mm a"
                            disableClock={true}
                            calendarType={'US'}
                        />
                    </InputGroup>
                </div>
                <div
                    css={css`
                        min-width: 264px;
                        width: clamp(264px, max-content);
                    `}
                >
                    <InputGroup className="mb-3 mr-2 flex-column">
                        <FormLabel className="mr-2 ">Tags: </FormLabel>
                        <TagSelectFilter
                            className="bolder"
                            css={css`
                                min-width: 264px;
                            `}
                            handleSelectCallback={handleTagSelect}
                            type="userform"
                            disabled={loadingFiles}
                        />
                    </InputGroup>
                </div>
                <div
                    css={css`
                        min-width: 264px;
                        width: clamp(264px, max-content);
                    `}
                >
                    <InputGroup className="mb-3 mr-2 flex-column">
                        <FormLabel className="mr-2 ">Submission Type: </FormLabel>
                        <Select
                            value={submissionType}
                            className="bolder"
                            name="submission type"
                            isClearable={true}
                            options={submissionTypes}
                            onChange={handleSubmissionType}
                            placeholder="Enter Submission Type"
                        />
                    </InputGroup>
                </div>
                <div
                    css={css`
                        min-width: 264px;
                        width: clamp(264px, max-content);
                    `}
                >
                    <InputGroup className="mb-3 mr-2 flex-column">
                        <FormLabel>Sort By ( Submitted At ): </FormLabel>
                        <div
                            style={{ display: 'flex', alignItems: 'center', gap: '3px' }}
                            css={css`
                                color: #333;
                                border: 1px solid #ccc;
                                height: 37.5px;
                                border-radius: 4px;
                                cursor: pointer;
                            `}
                            onClick={() => handleSort('createdAt')}
                        >
                            <div
                                css={css`
                                    display: flex;
                                    width: 100%;
                                    align-items: center;
                                    justify-content: center;
                                `}
                            >
                                <span>{sortBy?.direction === 'asc' ? 'Ascending' : 'Descending'}</span>
                                <SortedTableHeaderIcon size={16} sortConfig={sortBy} fieldName={'createdAt'} />
                            </div>
                        </div>
                    </InputGroup>
                </div>
            </div>
            <div
                css={css`
                    width: full;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    gap: 5px;
                `}
            >
                <Button
                    disabled={
                        isDownloading ||
                        !(
                            startDate?.length ||
                            endDate?.length ||
                            channel?.label?.length ||
                            member?.label?.length ||
                            formName?.label?.length
                        )
                    }
                    onClick={() => generatePdfAndDownloadZip(true)}
                >
                    <MdOutlineFileDownload />
                    {isDownloading ? 'Downloading...' : 'Bulk Download'}
                </Button>
                <AccumulatedView
                    form={formName}
                    channel={channel}
                    startDate={startDate}
                    endDate={endDate}
                    user={member}
                    sortBy={sortBy}
                />
            </div>

            {loadingFiles ? (
                <h4 className="text-center">
                    <VscLoading css={spin} fontSize="42" />
                </h4>
            ) : filteredForms.length ? (
                <div className="custom-card">
                    <ScrollContainer ref={scrollRef} onScroll={onScroll}>
                        <FormPackageTable className="w-100">
                            <thead>
                                <tr>
                                    <th>S.N</th>
                                    <th>Submitted by</th>
                                    <th>Form name</th>
                                    {/* <th>Created At</th> */}
                                    <th>Channel</th>
                                    <th>Contribution</th>
                                    <th>Submitted At</th>
                                </tr>
                            </thead>
                            <tbody>
                                <Fragment>
                                    {filteredForms.length > 0 &&
                                        filteredForms.map((form, i) => {
                                            formCounter++;
                                            return (
                                                <tr key={`${form._id}_${formCounter}`}>
                                                    <td>{(currentPage - 1) * 10 + (i + 1)}</td>
                                                    <td
                                                        css={css`
                                                            flex: 1;
                                                        `}
                                                    >
                                                        <div
                                                            css={css`
                                                                display: flex;
                                                                flex-direction: column;
                                                                gap: 0.5rem;
                                                            `}
                                                        >
                                                            <span
                                                                className={`${
                                                                    form?.userData?.name ? '' : 'text-danger'
                                                                } d-flex flex-row align-items-center`}
                                                            >
                                                                <Avatar item={form?.userData} />
                                                                <span
                                                                    className="d-flex flex-column"
                                                                    css={css`
                                                                        margin-left: 0.4rem;
                                                                    `}
                                                                >
                                                                    <div
                                                                        css={css`
                                                                            display: flex;
                                                                        `}
                                                                    >
                                                                        <span
                                                                            className="ml-2"
                                                                            css={css`
                                                                                font-size: 18px;
                                                                            `}
                                                                        >
                                                                            {form?.userData?.name
                                                                                ? form?.userData?.name
                                                                                : 'Deleted User'}
                                                                        </span>
                                                                        {
                                                                            <span
                                                                                css={css`
                                                                                    width: fit-content;
                                                                                    cursor: pointer;
                                                                                    margin-left: 10px;
                                                                                `}
                                                                                onClick={() =>
                                                                                    handleShowTagsModal(
                                                                                        form._id || form.id
                                                                                    )
                                                                                }
                                                                            >
                                                                                <AddTagsTooltip />
                                                                            </span>
                                                                        }
                                                                        {showTagsModal === (form?._id || form?.id) ? (
                                                                            <TagsModal
                                                                                show={!!showTagsModal}
                                                                                handleClose={handleCloseTagsModal}
                                                                                type={'userform'}
                                                                                tagAction={'assign'}
                                                                                objectId={form?._id || form?.id}
                                                                                prevTags={form?.tags}
                                                                                responseCallback={
                                                                                    updateTagsInSubmittedForms
                                                                                }
                                                                            />
                                                                        ) : null}
                                                                    </div>
                                                                    <ViewUserForm id={form._id} form={form}>
                                                                        <span
                                                                            className="btn btn-link"
                                                                            css={css`
                                                                                font-size: 14px;
                                                                                padding: 0;
                                                                            `}
                                                                        >
                                                                            <FaEye className="mr-3" />
                                                                            View this submission
                                                                        </span>
                                                                    </ViewUserForm>
                                                                </span>
                                                            </span>
                                                            <TagPreviewWithContainer
                                                                tags={form?.tags || []}
                                                                style={{ maxWidth: '50rem' }}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span className="d-flex flex-column">
                                                            <span
                                                                css={css`
                                                                    font-weight: 600;
                                                                    font-size: 16px;
                                                                `}
                                                            >
                                                                {form?.formData?.name}
                                                            </span>
                                                            <span
                                                                className="btn btn-link"
                                                                css={css`
                                                                    font-size: 14px;
                                                                    padding: 0;
                                                                    text-align: left;
                                                                `}
                                                                onClick={() => setForm(form.formData)}
                                                            >
                                                                <FaEye className="mr-3" />
                                                                View bulk submissions
                                                            </span>
                                                        </span>
                                                    </td>
                                                    {/* <td>
														{dayjs(form?.formData?.createdAt).format('MMMM D, YYYY h:mm A')}
													</td> */}

                                                    <td
                                                        className={`${
                                                            form?.chatroomData?.title ? '' : 'text-danger'
                                                        } text-left`}
                                                        css={css`
                                                            max-width: 300px;
                                                        `}
                                                    >
                                                        {form?.chatroomData?.title
                                                            ? form?.chatroomData?.title
                                                            : 'Channel Deleted'}
                                                    </td>
                                                    <td>
                                                        {form?.extraInfo?.history &&
                                                        form?.extraInfo?.history?.length ? (
                                                            <FormContributionHistory
                                                                id={form?._id || form?.id}
                                                                form={form}
                                                            >
                                                                <span
                                                                    className="btn btn-link"
                                                                    css={css`
                                                                        font-size: 14px;
                                                                        padding: 0;
                                                                    `}
                                                                >
                                                                    View Contribution
                                                                </span>
                                                            </FormContributionHistory>
                                                        ) : (
                                                            '-'
                                                        )}
                                                    </td>
                                                    <td>{dayjs(form?.createdAt).format('MMMM D, YYYY h:mm A')}</td>
                                                </tr>
                                            );
                                        })}
                                </Fragment>
                            </tbody>
                        </FormPackageTable>
                    </ScrollContainer>
                </div>
            ) : (
                <div
                    css={css`
                        background-color: white;
                        box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
                        border-radius: 5px;
                        padding: 15px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                    `}
                >
                    <img
                        src={FormSvg}
                        alt={'no documents found'}
                        css={css`
                            width: 350px;
                            margin: 1rem 0;
                        `}
                    />
                    <h2 className="text-center">No matching results</h2>
                    <div className="text-center">Try another search, or use search filters to find the document.</div>
                </div>
            )}
            <div className="p-4">
                <Pagination
                    totalRecords={totalForms}
                    key={totalForms}
                    pageLimit={10}
                    pageNeighbours={1}
                    onPageChanged={onPageChanged}
                />
            </div>
            <Modal css={ModalCSS} show={form} backdropClassName="bg-dark" onHide={hideFormPreview} centered size="lg">
                <Modal.Header closeButton>
                    <h4
                        css={css`
                            margin: 0;
                        `}
                    >
                        {form.name}
                    </h4>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <SubmittedFormsView
                        teamId={teamId}
                        form={form}
                        filters={{
                            channel: channel?.value,
                            userId: member?.value,
                            startDate: startDate,
                            endDate: endDate,
                        }}
                    />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default TeamSettingsGroupForms;
