import { css } from 'twin.macro';
import dayjs from 'dayjs';
import { useState } from 'react';
import Linkify from 'react-linkify';

import { Form } from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import { marginColor } from './Checkbox';
import { ElementStyled, elementClass } from './styles/ElementStyles';

const DateComponent = ({ data, elementRef, isPreview, error }) => {
    const [dateValue, setDateValue] = useState(data.value ? new Date(dayjs(data.value)) : new Date());
    const handleDateChange = (value) => {
        setDateValue(new Date(value));
    };

    return (
        <ElementStyled className={elementClass(data)}>
            <Form.Group controlId="formGridEmail">
                <Form.Label className="mr-4">
                    <h5 className="warpIt">
                        {data.fieldName}
                        <span css={marginColor}>{(data.hasOwnProperty('required') ? data.required : true) && '*'}</span>
                        :
                    </h5>
                </Form.Label>
                {data.instruction && (
                    <pre className="small text-muted warpIt preStyles">
                        <Linkify
                            componentDecorator={(decoratedHref, decoratedText, key) => (
                                <a target="blank" href={decoratedHref} key={key}>
                                    {decoratedText}
                                </a>
                            )}
                        >
                            {data.instruction}
                        </Linkify>
                    </pre>
                )}

                <DatePicker
                    value={dateValue}
                    onChange={handleDateChange}
                    clearIcon={null}
                    format="MM/dd/yyyy"
                    disabled={isPreview}
                />

                <Form.Control
                    type="text"
                    name={`${!isPreview ? `DatePicker-${data._id}` : 'DatePicker'}`}
                    className="date-picker"
                    ref={
                        elementRef &&
                        elementRef({
                            required: data.hasOwnProperty('required') ? data.required : true,
                        })
                    }
                    css={css`
                        position: absolute;
                        opacity: 0 !important;
                        width: 0 !important;
                    `}
                    disabled={isPreview}
                    // value={(getDateTime(dateValue) + getTimeZoneDiff()).slice(0, 10)}
                    value={dateValue.toISOString()}
                    readOnly
                />
                <Form.Text className="text-danger">
                    {error && error.type === 'required' && 'This field is required'}
                </Form.Text>
            </Form.Group>
        </ElementStyled>
    );
};

export default DateComponent;
