import { css } from 'twin.macro';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { fetchWrapper, getUserRole, toastWrapper } from '../../../_helpers';
import StaticPreview from './StaticPreview';
import { toast } from 'react-toastify';
import MultiSubmissionView from './MultiSubmissionView';
import { Tab, Tabs } from 'react-bootstrap';
import FileSaver from 'file-saver';
import { FormPdf } from '../../../_elements/FormPdf';
import { getTeamIdFromURL, isAdminOrTeamSettings } from '../../../_helpers';
import { PDFViewer } from '@react-pdf/renderer';

const ViewForm = ({
    match = null,
    formId = null,
    hide = null,
    showSignature,
    setFormTitle,
    canDownload,
    currUser,
    handleSetform = () => {},
}) => {
    const [formData, setFormData] = useState(null);
    const [isDownloading, setIsDownloading] = useState(false);
    const [loading, setLoading] = useState(true);
    const user = useSelector((state) => state.user.user);
    const teamId = getTeamIdFromURL();
    const isSettings = isAdminOrTeamSettings();
    const userId = user.id || user._id;

    const [key, setKey] = useState('staticform');
    const [form, setForm] = useState({});
    let id = null;
    if (formId) {
        id = formId;
    } else if (match) {
        id = match.params.id;
    }

    useEffect(() => {
        const getFormData = async () => {
            setLoading(true);
            try {
                if (id) {
                    await fetchWrapper.get(`userFormData/${id}`).then((res) => {
                        handleSetform(res);
                        let newFormData = Object.assign([], res.formData?.data);
                        if (newFormData.length > 0) {
                            setFormData(
                                newFormData.map((form) => {
                                    return {
                                        ...form,
                                        isFetched: true,
                                        value: (res?.data || []).find((f) => form._id === f.fieldName.split('-')[1])
                                            ?.value,
                                    };
                                })
                            );

                            setForm(res);
                            if (setFormTitle) setFormTitle(res.formData.name);
                        } else {
                            toast.error(
                                res?.status === 403
                                    ? "You don't have permission to view this Form"
                                    : 'Oops! File has been Corrupted.'
                            );
                            if (hide) {
                                hide();
                            }
                        }
                    });
                } else if (match) {
                    await fetchWrapper.get(`forms/${id}`).then((res) => {
                        setFormData(res.data);
                    });
                } else {
                    setFormData(null);
                }
            } catch (error) {
                toastWrapper.error(error?.message || 'Oops! File has been Corrupted.');
                console.log(error);
            } finally {
                setLoading(false);
            }
        };

        getFormData();
    }, [id, hide, match, setFormTitle]);

    const generatePdfAndDownload = async (singleFile = true) => {
        setIsDownloading(true);
        try {
            if (singleFile) {
                const blob = await FormPdf([form], 'combined');
                return FileSaver.saveAs(
                    blob,
                    `${form?.formData?.name || 'submittedForm'}_${form?.userData?.name || 'user'}.pdf`
                );
            }
        } catch (error) {
            console.log('ERROR IN PDF GENERATION => ', error);
        } finally {
            setIsDownloading(false);
        }
    };
    const canDownloadPDF = () => {
        if (currUser?.role === 'admin') {
            return true;
        }
        const teamRole = currUser?.teamList?.find(
            (teamObj) => teamObj?.team?.id === teamId || teamObj?.team?._id === teamId
        )?.role;
        if (['owner', 'teamManager'].includes(teamRole)) {
            return true;
        }
        return false;
    };

    const isDraft = form?.type === 'normal' && form?.submissionType === 'draft';
    const canComplete =
        ['admin', 'owner', 'teamManager', 'supervisor'].includes(getUserRole(currUser, form?.team, form?.chatroom)) ||
        form?.user === userId ||
        (form?.visibleTo || []).includes('user');
    const canSubmit = canComplete;

    if (loading) {
        return (
            <div
                css={css`
                    width: 100%;
                    min-height: 50vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                `}
            >
                <img src="/images/ball-triangle.svg" alt="loader" />
            </div>
        );
    }

    return (
        <div>
            <div className="d-flex flex-column">
                {(canDownload || canDownloadPDF()) && (
                    <div>
                        {isDownloading ? (
                            <p className="float-right">Downloading...</p>
                        ) : (
                            <button
                                onClick={() => generatePdfAndDownload()}
                                className="btn btn-info float-right border border-white btn-lg mb-2"
                            >
                                Download PDF
                            </button>
                        )}
                    </div>
                )}
                <Tabs
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                    css={css`
                        .active {
                            .tab-title {
                                color: #495057 !important;
                            }
                        }
                    `}
                >
                    <Tab eventKey="staticform" title={<span className="tab-title">Static Form</span>}>
                        {key === 'staticform' && (
                            <StaticPreview
                                formElements={formData}
                                onlyView={Boolean(formId)}
                                isPreview={!isDraft}
                                userFormData={form || {}}
                                handleCloseModal={hide}
                                isDraft={isDraft}
                                canSubmit={canSubmit}
                                canComplete={canComplete}
                            />
                        )}
                    </Tab>
                    {(canDownload || canDownloadPDF()) && (
                        <Tab eventKey="pdf" title={<span className="tab-title">PDF</span>}>
                            {key === 'pdf' && (
                                <div
                                    css={css`
                                        height: 70vh;
                                        overflow: auto;
                                    `}
                                >
                                    <PDFViewer
                                        width="100%"
                                        height="100%"
                                        css={css`
                                            border: none;
                                            outline: none;
                                        `}
                                        showToolbar={false}
                                    >
                                        {FormPdf(form, 'component')}
                                    </PDFViewer>
                                </div>
                            )}
                        </Tab>
                    )}
                </Tabs>
            </div>

            <MultiSubmissionView showSignature={showSignature} form={form} userId={userId} hide={hide} id={id} />
        </div>
    );
};

const mapStateToProps = (state) => ({
    currUser: state.user.user,
});

export default connect(mapStateToProps, {})(ViewForm);
