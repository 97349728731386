import styled from '@emotion/styled';

export const ElementStyled = styled('div')`
    &.modified {
        border: 3px solid #ea9941;
        border-radius: 5px;
        padding: 4px;
    }
`;

export const elementClass = (data = {}) => {
    try {
        return data?.modified && data?.draftMode ? 'modified' : '';
    } catch (e) {
        return '';
    }
};
