import * as ActionTypes from './ActionTypes';
import { fetchWrapper, prepareEvent } from '../../_helpers';

export const getAllTask =
    (page = 1, limit = 100) =>
    async (dispatch) => {
        dispatch({ type: ActionTypes.START_TASK_LOADING });
        try {
            const response = await fetchWrapper.get(`task?page=${page}&limit=${limit}`);
            dispatch(setTaskList(response, page === 1));
        } catch (error) {
            console.log('ERROR IN FETCHING TASK =>', error);
        } finally {
            dispatch({ type: ActionTypes.STOP_TASK_LOADING });
        }
    };
export const getUserTask =
    (userId, page = 1, limit = 100) =>
    async (dispatch) => {
        dispatch({ type: ActionTypes.START_TASK_LOADING });
        try {
            const response = await fetchWrapper.get(`/task/${userId}/user?page=${page}&limit=${limit}`);
            dispatch(setTaskList(response, page === 1));
        } catch (error) {
            console.log('ERROR IN FETCHING TASK =>', error);
        } finally {
            dispatch({ type: ActionTypes.STOP_TASK_LOADING });
        }
    };
export const getTaskByMonth =
    (monthYear, page = 1, limit = 100) =>
    async (dispatch) => {
        dispatch({ type: ActionTypes.START_TASK_LOADING });
        try {
            const response = await fetchWrapper.get(`task?month=${monthYear}&limit=${limit}&page=${page}`);
            dispatch(setTaskList(response, page === 1));
        } catch (error) {
            console.log('ERROR IN FETCHING TASK =>', error);
        } finally {
            dispatch({ type: ActionTypes.STOP_TASK_LOADING });
        }
    };
export const getTaskByassignedToId =
    (assignedTo, monthYear, page = 1, limit = 100) =>
    async (dispatch) => {
        dispatch({ type: ActionTypes.START_TASK_LOADING });
        try {
            const response = await fetchWrapper.get(
                `task?limit=${limit}&page=${page}${assignedTo ? `&assignedUser=${assignedTo}` : ''}&month=${monthYear}`
            );
            dispatch(setTaskList(response, page === 1));
        } catch (error) {
            console.log('ERROR IN FETCHING TASK =>', error);
        } finally {
            dispatch({ type: ActionTypes.STOP_TASK_LOADING });
        }
    };
export const getTaskByChannelId =
    (channelId, monthYear, page = 1, limit = 100) =>
    async (dispatch) => {
        dispatch({ type: ActionTypes.START_TASK_LOADING });
        try {
            const response = await fetchWrapper.get(
                `task?assignedChannel=${channelId}&month=${monthYear}&limit=${limit}&page=${page}`
            );
            dispatch(setTaskList(response, page === 1));
        } catch (error) {
            console.log('ERROR IN FETCHING TASK =>', error);
        } finally {
            dispatch({ type: ActionTypes.STOP_TASK_LOADING });
        }
    };
export const getTaskByChannelIdAndUserId =
    (channelId, userId, monthYear, page = 1, limit = 100) =>
    async (dispatch) => {
        dispatch({ type: ActionTypes.START_TASK_LOADING });
        try {
            const response = await fetchWrapper.get(
                `task?limit=${limit}&page=${page}&assignedChannel=${channelId}&assignedUser=${userId}&month=${monthYear}`
            );
            dispatch(setTaskList(response, page === 1));
        } catch (error) {
            console.log('ERROR IN FETCHING TASK =>', error);
        } finally {
            dispatch({ type: ActionTypes.STOP_TASK_LOADING });
        }
    };
export const deleteTheTask = (taskId) => async (dispatch) => {
    try {
        await fetchWrapper.delete(`task/${taskId}`);
        dispatch(deleteTask(taskId));
    } catch (error) {
        console.log(error, 'Error delete task');
    }
};

export const getTheTask = (taskId) => async (dispatch) => {
    try {
        const response = await fetchWrapper.get(`task/${taskId}`);
        dispatch(editTask(response));
        return 1;
    } catch (error) {
        if (error.name === 'AbortError') {
            return 0;
        }
        dispatch({
            type: ActionTypes.SET_SHOW_MESSAGE,
            payload: { error: true, message: error.message },
        });
        return 0;
    }
};

export const editTheTask = (taskId, task, selectedMonthYear) => async (dispatch) => {
    try {
        const response = await fetchWrapper.put(
            `task/${taskId}${selectedMonthYear ? `?month=${selectedMonthYear}` : ''}`,
            prepareEvent({ ...task, _id: undefined })
        );
        let newTask = { ...response };
        if (task.forms) {
            let newSubTasks = task.assignedUser.map((userId) => {
                let newForms = task.forms.map((formID) => ({
                    form: formID,
                    submitted: false,
                }));
                // console.log(task, 'ts');
                return {
                    assignedUser: userId,
                    date: task.date,
                    forms: newForms,
                };
            });
            newTask.subTasks = newSubTasks;
        }
        dispatch(editTask(newTask));
        dispatch({
            type: ActionTypes.SET_SHOW_MESSAGE,
            payload: { error: false, message: 'Task edited successfully' },
        });
        return response;
    } catch (error) {
        dispatch({
            type: ActionTypes.SET_SHOW_MESSAGE,
            payload: { error: true, message: error.message },
        });
        return 0;
    }
};

export const editMyTask =
    (taskId, task = {}) =>
    async (dispatch) => {
        try {
            let response;
            if (task?.userStartReminders || task?.userEndRemainders) {
                response = await fetchWrapper.patch(`task/${taskId}`, { ...task, _id: undefined });
            } else {
                response = await fetchWrapper.patch(`task/${taskId}/notification`, { ...task, _id: undefined });
            }
            let newTask = { ...response };
            if (task.forms) {
                let newSubTasks = task.assignedUser.map((userId) => {
                    let newForms = task.forms.map((formID) => ({
                        form: formID,
                        submitted: false,
                    }));
                    return {
                        assignedUser: userId,
                        date: task.date,
                        forms: newForms,
                    };
                });
                newTask.subTasks = newSubTasks;
            }
            dispatch(editTask(newTask));
            // dispatch({
            //     type: ActionTypes.SET_SHOW_MESSAGE,
            //     payload: { error: false, message: 'Task edited successfully' },
            // });
            return response;
        } catch (error) {
            dispatch({
                type: ActionTypes.SET_SHOW_MESSAGE,
                payload: { error: true, message: error.message },
            });
            return 0;
        }
    };

export const editTaskStatus =
    (taskId, task, completion = false) =>
    async (dispatch) => {
        try {
            const response = await fetchWrapper.put(`task/${taskId}/status`, task);
            let newTask = { ...response };
            // console.log('newTask', newTask);
            dispatch(editTask(newTask));
            if (completion) {
                if (completion === 'incomplete') {
                    dispatch({
                        type: ActionTypes.SET_SHOW_MESSAGE,
                        payload: { error: false, message: 'Task completion reverted' },
                    });
                } else {
                    dispatch({
                        type: ActionTypes.SET_SHOW_MESSAGE,
                        payload: { error: false, message: 'Task Completed' },
                    });
                }
            } else {
                dispatch({
                    type: ActionTypes.SET_SHOW_MESSAGE,
                    payload: { error: false, message: 'Task edited successfully' },
                });
            }
            return 1;
        } catch (error) {
            dispatch({
                type: ActionTypes.SET_SHOW_MESSAGE,
                payload: { error: true, message: error.message },
            });
            return 0;
        }
    };

export const editTaskDate =
    ({ _id, demandedDate, mode, fromPackage, packageData }) =>
    async (dispatch) => {
        try {
            if (fromPackage) {
                const message =
                    mode === 'add-calendar' ? 'Plan added to your calendar' : 'Plan removed from your calendar';
                let newTask = { ...packageData };
                dispatch(editTask(newTask));
                dispatch({
                    type: ActionTypes.SET_SHOW_MESSAGE,
                    payload: { error: false, message },
                });
                return;
            }
            if (mode === 'remove-calendar') {
                const response = await fetchWrapper.put(`task/map/remove/${_id}`);
                if (!response?.id || !response?._id) {
                    dispatch({
                        type: ActionTypes.SET_SHOW_MESSAGE,
                        payload: { error: false, message: 'Failed to remove event from calendar' },
                    });
                    return;
                }
                let newTask = { ...response };
                dispatch(editTask(newTask));
                dispatch({
                    type: ActionTypes.SET_SHOW_MESSAGE,
                    payload: { error: false, message: 'Event removed from your calendar' },
                });
            } else if (mode === 'add-calendar') {
                const response = await fetchWrapper.put(`task/map/${_id}`, demandedDate);
                if (response?.id || response?._id) {
                } else {
                    dispatch({
                        type: ActionTypes.SET_SHOW_MESSAGE,
                        payload: { error: false, message: 'Failed to add event to calendar' },
                    });
                    return;
                }
                let newTask = {
                    ...response,
                };
                dispatch(editTask(newTask));
                dispatch({
                    type: ActionTypes.SET_SHOW_MESSAGE,
                    payload: { error: false, message: 'Event added to your calendar' },
                });
            }
            return 1;
        } catch (error) {
            dispatch({
                type: ActionTypes.SET_SHOW_MESSAGE,
                payload: { error: true, message: error.message },
            });
            return 0;
        }
    };

export const postATask = (task, assignedBy, selectedMonthYear) => async (dispatch) => {
    try {
        const response = await fetchWrapper.post(
            `task${selectedMonthYear ? `?month=${selectedMonthYear}` : ''}`,
            prepareEvent({ ...task, _id: undefined })
        );

        let newTask = { ...response, assignedBy: assignedBy };

        if (task.forms) {
            let newSubTasks = task.assignedUser.map((userId) => {
                let newForms = task.forms.map((formID) => ({
                    form: formID,
                    submitted: false,
                }));
                return {
                    assignedUser: userId,
                    date: task.date.toISOString(),
                    forms: newForms,
                };
            });
            newTask.subTasks = newSubTasks;
        }

        dispatch(setNewTask(newTask));
        dispatch({
            type: ActionTypes.SET_SHOW_MESSAGE,
            payload: { error: false, message: 'Event created successfully' },
        });
        return 1;
    } catch (error) {
        dispatch({
            type: ActionTypes.SET_SHOW_MESSAGE,
            payload: { error: true, message: error.message },
        });
        return 0;
    }
};
export const getPublicTask = (eventType, assignedChannel) => async (dispatch) => {
    try {
        const response = await fetchWrapper.get(
            `/publicRoomTasks?limit=100${eventType ? `&eventType=${eventType}` : ''}${
                assignedChannel ? `&assignedChannel=${assignedChannel}` : ''
            }`
        );
        dispatch(setTaskList(response, false));
    } catch (error) {
        if (error.name === 'AbortError') {
            return;
        }
        console.log('ERROR IN FETCHING TASK =>', error);
    }
};

export const getPublicTaskPackage = (taskId) => async (dispatch) => {
    try {
        const response = await fetchWrapper.get(`/publicRoomTasks/package/${taskId}`);
        response?.taskData?.forEach((data) => {
            dispatch(editTask({ ...data, eventPackage: response }));
        });
        return response;
    } catch (error) {
        console.log('ERROR IN FETCHING PLAN =>', error);
    }
};

export const getPrivateTaskPackage = (packageId) => async (dispatch) => {
    try {
        const [response] = await fetchWrapper.get(`/activityPackages/${packageId}`);
        response?.taskData?.forEach((data) => {
            dispatch(editTask({ ...data, eventPackage: response }));
        });
        return response;
    } catch (error) {
        console.log('ERROR IN FETCHING PLAN =>', error);
    }
};

export const updateTaskFormOnAddRemove =
    (editedTaskResponse = {}) =>
    async (dispatch) => {
        try {
            dispatch(editTask({ ...editedTaskResponse }));
        } catch (error) {
            console.log('ERROR IN UPDATING TASK =>', error);
        }
    };

export const updateTaskFormStatus =
    ({ formId, userId, taskId, subTasks = [] }) =>
    async (dispatch) => {
        try {
            const updatedSubTasks = subTasks.map((task) => {
                if (task.assignedUser === userId) {
                    const updatedForms = task.forms.map((form) => {
                        if (form.form === formId) {
                            form.submitted = true;
                        }
                        return form;
                    });
                    return { ...task, forms: updatedForms };
                }
                return task;
            });
            dispatch(editTask({ subTasks: updatedSubTasks, _id: taskId }));
        } catch (error) {
            console.log('ERROR IN UPDATING TASK FORM =>', error);
        }
    };

export const setTaskList = (tasklist, replaceOld = false) => ({
    type: ActionTypes.SET_TASKLIST,
    payload: { ...tasklist, replaceOld },
});

const setNewTask = (newTask) => ({
    type: ActionTypes.SET_NEW_TASK,
    payload: newTask,
});

const editTask = (editedTask) => ({
    type: ActionTypes.SET_EDIT_TASK,
    payload: editedTask,
});

const deleteTask = (taskId) => ({
    type: ActionTypes.DELETE_THE_TASK,
    payload: taskId,
});
