import React, { Fragment, useEffect } from 'react';
import { useState } from 'react';
import Linkify from 'react-linkify';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PdfRenderer from '../../../OctetRenderer/PdfRenderer';

const PdfPreviewer = (props) => {
    let { roomId } = useParams();
    let user = useSelector((state) => state.user.user);
    let userChannel = (user?.roomList || []).filter((room) => room.room).find((room) => room.room.id === roomId);
    const thisTeam = (user?.teamList || []).filter((team) => team?.team?._id === userChannel?.room?.team);
    let userTeamRole = thisTeam[0]?.role || null;
    let userChannelRole = (userChannel || {}).role;
    const [value, setValue] = useState(null);
    useEffect(() => {
        let timeOut = setTimeout(() => {
            try {
                if (props.data.data) {
                    if (props.data.data?.file) {
                        setValue(props.data.data.file);
                    } else if (props.data.data[0]?.file) {
                        setValue(props.data.data[0].file);
                    }
                }
            } catch (err) {
                console.log(err, 'cant get pdf');
            }
        }, 10);
        return () => {
            clearTimeout(timeOut);
        };
    }, [props.data.data]);

    const isDownloadable = props.data.data && props.data.data[0]?.feature?.isDownloadable;
    let downloadableChannelRoles = [];
    if (isDownloadable) {
        downloadableChannelRoles = props.data.data && props.data.data[0]?.feature?.downloadableChannelRoles;
    }
    const downloadableTeamRoles = ['owner', 'teamManager'];
    return (
        <Fragment>
            <div className="d-flex flex-column w-100">
                <h5>{props.data.fieldName}</h5>
                <div>
                    <pre className="small text-muted warpIt preStyles">
                        <Linkify
                            componentDecorator={(decoratedHref, decoratedText, key) => (
                                <a target="blank" href={decoratedHref} key={key}>
                                    {decoratedText}
                                </a>
                            )}
                        >
                            {props.data.instruction}
                        </Linkify>
                    </pre>
                </div>
                {value && (
                    <div className="overflow-auto" key={value}>
                        {(user.role === 'admin' ||
                            downloadableTeamRoles.includes(userTeamRole) ||
                            downloadableChannelRoles.includes(userChannelRole)) && (
                            <div className="d-flex justify-content-end">
                                <a href={value} target="_blank" download className="btn btn-info" rel="noreferrer">
                                    {' '}
                                    Download{' '}
                                </a>
                            </div>
                        )}

                        <PdfRenderer uri={value} />
                    </div>
                )}
            </div>
        </Fragment>
    );
};

export default PdfPreviewer;
