import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { css } from 'twin.macro';

const TableCell = ({ cell, style = {}, isSticky }) => {
    const stickyStyles = isSticky
        ? css`
              position: sticky;
              left: 0;
              background-color: #ccc;
              z-index: 1;
          `
        : null;

    return (
        <td
            css={css`
                border: 1px solid #ddd;
                padding: 8px;
                ${stickyStyles}
            `}
            style={style}
        >
            {cell?.image ? (
                <img
                    src={cell.image + '?noCache=' + Math.random().toString()}
                    alt={cell.value || 'Image'}
                    css={css`
                        max-width: 100%;
                        height: auto;
                    `}
                />
            ) : (
                cell?.value || ''
            )}
        </td>
    );
};

TableCell.propTypes = {
    cell: PropTypes.shape({
        value: PropTypes.string,
        image: PropTypes.string,
        style: PropTypes.object,
    }),
    style: PropTypes.object,
    isSticky: PropTypes.bool,
};

const TableRow = ({ rowData, rowIndex, columnWidths, isSticky }) => {
    return (
        <tr key={rowIndex}>
            {rowData.map((cell, cellIndex) => (
                <TableCell
                    key={cellIndex}
                    cell={cell}
                    style={{ ...cell?.style, minWidth: '224px' }}
                    isSticky={cellIndex === 0 ? true : false}
                />
            ))}
        </tr>
    );
};

TableRow.propTypes = {
    rowData: PropTypes.array.isRequired,
    rowIndex: PropTypes.number.isRequired,
    columnWidths: PropTypes.array.isRequired,
    isSticky: PropTypes.bool,
};

const Table = ({ data = [], columns = [], customStyles, tableRef, title }) => {
    return (
        <Fragment>
            <h1 className="text-center w-100">{title || ''}</h1>

            <table
                css={css`
                    width: 100%;
                    border-collapse: collapse;
                `}
            >
                <tbody>
                    {data.length > 0 ? (
                        data.map((row, rowIndex) => (
                            <TableRow key={rowIndex} rowData={row} rowIndex={rowIndex} isSticky={true} />
                        ))
                    ) : (
                        <tr>
                            <td
                                colSpan={columns.length}
                                css={css`
                                    border: 1px solid #ddd;
                                    padding: 8px;
                                `}
                                style={{ minWidth: '264px' }}
                            >
                                No data available
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </Fragment>
    );
};

Table.propTypes = {
    data: PropTypes.array,
    columns: PropTypes.array,
    customStyles: PropTypes.object,
};

export default Table;
