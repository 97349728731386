import React from 'react';
import Linkify from 'react-linkify';
import { Form } from 'react-bootstrap';
import { marginColor } from './Checkbox';
import { ElementStyled, elementClass } from './styles/ElementStyles';

const Radio = ({ data, isPreview, isDisabled, elementRef, error }) => (
    <ElementStyled className={elementClass(data)}>
        <Form.Group controlId="formGridEmail">
            <Form.Label>
                <h5 className="warpIt">
                    {data.fieldName}
                    <span css={marginColor}>{(data.hasOwnProperty('required') ? data.required : true) && '*'}</span>
                </h5>
            </Form.Label>
            {data.instruction && (
                <pre className="small text-muted warpIt preStyles">
                    <Linkify
                        componentDecorator={(decoratedHref, decoratedText, key) => (
                            <a target="blank" href={decoratedHref} key={key}>
                                {decoratedText}
                            </a>
                        )}
                    >
                        {data.instruction}
                    </Linkify>
                </pre>
            )}
            {data.data?.length
                ? data.data.map((option) => (
                      <Form.Check
                          key={option.value || option.label}
                          type="radio"
                          label={option.text || option.label}
                          name={!isPreview || data.isFetched ? data.name || `${data.key}-${data._id}` : `${data.key}`}
                          id={option.value || option.label}
                          value={option.value}
                          defaultChecked={option.value === data.value}
                          disabled={isPreview || data.isDisabled || isDisabled}
                          ref={
                              elementRef &&
                              elementRef({
                                  required: data.hasOwnProperty('required') ? data.required : true,
                                  ...(data.validation || {}),
                              })
                          }
                      />
                  ))
                : null}
            <Form.Text className="text-danger">
                {error && error.type === 'required' ? error?.message || 'This field is required' : error?.message}
            </Form.Text>
        </Form.Group>
    </ElementStyled>
);

export default Radio;
