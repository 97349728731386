import { css } from 'twin.macro';
import { useState, useEffect, Fragment } from 'react';
import { Modal, Button, Tab, Tabs } from 'react-bootstrap';
import { TagPreviewWithContainer } from '../../Features/Common/Tags/TagsPreview';
import { fetchWrapper } from '../../../_helpers';
import Select from 'react-select';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import FileSaver from 'file-saver';
import { FormPdf } from '../../../_elements/FormPdf';
import { PDFViewer } from '@react-pdf/renderer';
import StaticPreview from './StaticPreview';

const FormContributionHistory = ({ id, children, form: oldForm }) => {
    const [show, setShow] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [key, setKey] = useState('staticform');
    const [activeUserFormData, setActiveUserFormData] = useState(null);
    const [submissionTypeOptions, setSubmissionTypeOptions] = useState([]);
    const [formData, setFormData] = useState(null);
    const [submissionIndex, setSubmissionIndex] = useState(9e9);
    const [form, setForm] = useState({});

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleSubmissionType = (e) => {
        const index = e?.value?.index;
        setActiveUserFormData(e || null);
        handleSetFormData(submissionTypeOptions, index);
        setSubmissionIndex(index);
    };

    const handleSetFormData = (data, index = 0) => {
        setActiveUserFormData(data[index]);
        setForm(data[index]?.value);
        const tempUserFormData = data[index]?.value?.formData?.data || [];
        const tempUserData = data[index]?.value?.data || [];
        const tempUserDataPrev = data[index + 1]?.value?.data || [];
        setFormData(
            (tempUserFormData || []).map((formElement) => {
                const value = (tempUserData || []).find((f) => formElement._id === f.fieldName.split('-')[1])?.value;
                const prevValue = (tempUserDataPrev || []).find(
                    (f) => formElement._id === f.fieldName.split('-')[1]
                )?.value;
                const modified = (() => {
                    try {
                        return value !== prevValue && index !== (data || []).length - 1;
                    } catch {
                        return false;
                    }
                })();
                return {
                    ...formElement,
                    isFetched: true,
                    value,
                    modified,
                    draftMode: true,
                };
            })
        );
    };

    const fetchUserFormData = async () => {
        try {
            const response = await fetchWrapper.get(`userFormData/${id}?contributors=true`);

            const data = (response || [])
                .sort((a, b) => {
                    const dateA = new Date(a?.updatedAt || a?.createdAt || 0);
                    const dateB = new Date(b?.updatedAt || b?.createdAt || 0);
                    return dateB - dateA;
                })
                .map((userFormData, index) => {
                    const date = dayjs(userFormData?.updatedAt || userFormData?.createdAt || '').format(
                        'MMMM D, YYYY h:mm A'
                    );
                    return {
                        label: `[${index + 1}] ${userFormData?.userData?.name || ''} - ${date}`,
                        value: { ...userFormData, index },
                    };
                });

            setSubmissionTypeOptions(data);
            if (data.length) {
                handleSetFormData(data, 0);
            }
        } catch (error) {
            if (error?.name === 'AbortError') return;
            console.error(error);
            toast.error(error?.message);
        }
    };

    useEffect(() => {
        if (id && show) {
            fetchUserFormData(id);
        }
    }, [id, show]);
    const generatePdfAndDownload = async (singleFile = true) => {
        setIsDownloading(true);
        try {
            if (singleFile) {
                const blob = await FormPdf([form], 'combined');
                return FileSaver.saveAs(
                    blob,
                    `${form?.formData?.name || 'submittedForm'}_${form?.userData?.name || 'user'}.pdf`
                );
            }
        } catch (error) {
            console.log('ERROR IN PDF GENERATION => ', error);
        } finally {
            setIsDownloading(false);
        }
    };

    if (!id) {
        return null;
    }

    return (
        <Fragment>
            {children ? <span onClick={handleShow}>{children}</span> : null}
            <Modal show={show} onHide={handleClose} size="xl" centered>
                <Modal.Header>
                    <div>
                        {oldForm?.formData?.name}
                        <TagPreviewWithContainer tags={oldForm?.tags || []} />
                        <div
                            css={css`
                                display: flex;
                                align-items: center;
                                margin-top: 8px;
                            `}
                        >
                            <div className="mr-4">Drafts History:</div>
                            <div
                                css={css`
                                    align-items: center;
                                    margin-top: 8px;
                                `}
                            >
                                <Select
                                    value={activeUserFormData}
                                    className="bolder"
                                    name="Submitted by "
                                    isClearable={false}
                                    options={submissionTypeOptions}
                                    onChange={handleSubmissionType}
                                    placeholder="Enter Submission Type"
                                    css={css`
                                        width: 700px;
                                    `}
                                />
                            </div>
                        </div>
                        <div className="mt-2">
                            <span
                                css={css`
                                    width: 16px;
                                    height: 16px;
                                    color: #ea9941;
                                    background-color: #ea9941;
                                `}
                            >
                                ___
                            </span>{' '}
                            : Modified Field
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Fragment>
                        {formData ? (
                            <div key={submissionIndex}>
                                <div className=" d-flex flex-column">
                                    <div>
                                        {isDownloading ? (
                                            <p className="float-right">Downloading...</p>
                                        ) : (
                                            <button
                                                onClick={() => generatePdfAndDownload()}
                                                className="btn btn-info float-right border border-white btn-lg mb-2"
                                            >
                                                Download PDF
                                            </button>
                                        )}
                                    </div>
                                    <Tabs
                                        activeKey={key}
                                        onSelect={(k) => setKey(k)}
                                        className="mb-3"
                                        css={css`
                                            .active {
                                                .tab-title {
                                                    color: #495057 !important;
                                                }
                                            }
                                        `}
                                    >
                                        <Tab
                                            eventKey="staticform"
                                            title={<span className="tab-title">Static Form</span>}
                                        >
                                            {key === 'staticform' && (
                                                <StaticPreview
                                                    formElements={formData}
                                                    onlyView={true}
                                                    isPreview={true}
                                                    userFormData={{}}
                                                    handleCloseModal={handleClose}
                                                />
                                            )}
                                        </Tab>
                                        <Tab eventKey="pdf" title={<span className="tab-title">PDF</span>}>
                                            {key === 'pdf' && (
                                                <div
                                                    css={css`
                                                        height: 70vh;
                                                        overflow: auto;
                                                    `}
                                                >
                                                    <PDFViewer
                                                        width="100%"
                                                        height="100%"
                                                        css={css`
                                                            border: none;
                                                            outline: none;
                                                        `}
                                                        showToolbar={false}
                                                    >
                                                        {FormPdf(form, 'component')}
                                                    </PDFViewer>
                                                </div>
                                            )}
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        ) : (
                            <div
                                css={css`
                                    width: 100%;
                                    min-height: 50vh;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                `}
                            >
                                <img src="/images/ball-triangle.svg" alt="loader" />
                            </div>
                        )}
                    </Fragment>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default FormContributionHistory;
