import { css } from 'twin.macro';
import React, { useState } from 'react';
import moment from 'moment';
import { Form } from 'react-bootstrap';
import Linkify from 'react-linkify';
import { marginColor } from './Checkbox';
import { ElementStyled, elementClass } from './styles/ElementStyles';

const TimeComponent = ({ data, elementRef, isPreview, error }) => {
    const [time, setTime] = useState(() => {
        if (data?.value && data?.value !== 'undefined' && data?.value !== 'Invalid date') {
            if (data.value.length < 6) {
                return data.value;
            } else {
                return moment(data.value).format('HH:mm');
            }
        } else {
            return moment(new Date()).format('HH:mm');
        }
    });
    const handleTimeChange = (event) => {
        setTime(event.target.value);
    };
    return (
        <ElementStyled className={elementClass(data)}>
            <Form.Group controlId="formGridEmail">
                <Form.Label>
                    <h5 className="warpIt">
                        {data.fieldName}
                        <span css={marginColor}>{(data.hasOwnProperty('required') ? data.required : true) && '*'}</span>
                    </h5>
                </Form.Label>

                {data.instruction && (
                    <pre className="small text-muted warpIt preStyles">
                        <Linkify
                            componentDecorator={(decoratedHref, decoratedText, key) => (
                                <a target="blank" href={decoratedHref} key={key}>
                                    {decoratedText}
                                </a>
                            )}
                        >
                            {data.instruction}
                        </Linkify>
                    </pre>
                )}
                <Form.Control
                    type="time"
                    className="time-picker"
                    disabled={isPreview}
                    value={time}
                    onChange={handleTimeChange}
                />
                <Form.Control
                    type="text"
                    name={`${!isPreview ? `TimePicker-${data._id}` : 'TimePicker'}`}
                    className="time-picker"
                    ref={
                        elementRef &&
                        elementRef({
                            required: data.hasOwnProperty('required') ? data.required : true,
                        })
                    }
                    css={css`
                        position: absolute;
                        opacity: 0 !important;
                    `}
                    disabled={isPreview}
                    value={moment(time, ['HH:mm']).format()}
                    readOnly
                />
                <Form.Text className="text-danger">
                    {error && error.type === 'required' && 'This field is required'}
                </Form.Text>
            </Form.Group>
        </ElementStyled>
    );
};

export default TimeComponent;
