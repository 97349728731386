import { css } from 'twin.macro';
import { Fragment, useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import FormElement from './FormElement';
import { useForm } from 'react-hook-form';
import { fetchWrapper } from '../../../_helpers';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

const StaticPreview = ({
    formElements,
    elementRef,
    isPreview,
    errors,
    onlyView,
    userFormData = {},
    handleCloseModal = () => {},
    isDraft = false,
    canSubmit = false,
    canComplete = false,
    createForm = false,
}) => {
    const {
        register,
        handleSubmit,
        errors: _errors,
    } = useForm({
        mode: 'onBlur',
    });

    const [isBusy, setIsBusy] = useState(false);
    const [forceSubmit, setForceSubmit] = useState(false);
    const onSubmit = async (data, submitMode = true) => {
        const userFormDataId = userFormData?._id || userFormData?.id;
        if (!userFormDataId) {
            return toast.error('UserFormData id is not provided');
        }
        try {
            setIsBusy(true);
            const keys = Object.keys(data);
            const form_data = new FormData();
            for (let i = 0; i < keys.length; i++) {
                const formInfo = (userFormData?.formData?.data || []).find(
                    (form) => form._id === keys[i].split('-')[1]
                );
                const value =
                    typeof data[keys[i]] === 'object'
                        ? keys[i].split('-')[0] === 'Image' ||
                          keys[i].split('-')[0] === 'File' ||
                          keys[i].split('-')[0] === 'AudioFile'
                            ? data[keys[i]][0]
                            : JSON.stringify(data[keys[i]])
                        : formInfo?.type === 'date'
                          ? data[keys[i]].replace(/\//g, '-')
                          : data[keys[i]];

                if (formInfo) {
                    form_data.append(`fieldName[${i}]`, keys[i]);
                    form_data.append(`value[${i}]`, value);
                    form_data.append(`type[${i}]`, formInfo ? formInfo?.type : 'image');
                    form_data.append(`label[${i}]`, formInfo ? formInfo.key : 'image');
                }
            }

            form_data.append(`formType`, userFormData?.type);
            form_data.append(`form`, userFormData?.form);
            form_data.append(`chatroom`, userFormData?.chatroom);
            form_data.append(`user`, localStorage.getItem('userId'));
            form_data.append('visibleTo', userFormData?.visibleTo);
            form_data.append('userFormData', userFormData._id);
            form_data.append('submissionType', submitMode ? 'completed' : 'draft');
            await fetchWrapper.put(`userFormData/${userFormDataId}`, form_data, true);
        } catch (e) {
            console.log(e, 'FORM SUBMITTED ERROR');
            toast.error(e?.message || 'Something went wrong');
        } finally {
            setIsBusy(false);
            handleCloseModal();
        }
    };

    return (
        <Card
            className="p-2"
            css={css`
                min-height: 150px;
                height: fit-content;
            `}
        >
            <Fragment>
                {formElements &&
                    Array.isArray(formElements) &&
                    formElements.length > 0 &&
                    formElements.map((form) => (
                        <div
                            key={form._id}
                            className={`form-element `}
                            css={css`
                                margin-bottom: 5px;
                                padding: 1rem 0.5rem 1rem 1rem;
                                overflow-wrap: break-word;
                            `}
                        >
                            <FormElement
                                form={{ ...(form || {}), required: isDraft && !forceSubmit ? false : form?.required }}
                                elementRef={elementRef || register}
                                isPreview={isPreview}
                                errors={errors || _errors}
                            />
                        </div>
                    ))}
            </Fragment>
            {userFormData?._id && isDraft && (
                <div className="text-center">
                    <Button
                        type="button"
                        variant="primary"
                        css={css`
                            font-size: 16px;
                            border-radius: 16px;
                            margin-top: 1rem;
                            margin-left: 1rem;
                            padding: 0.5rem 5rem;
                        `}
                        onClick={() => {
                            Swal.fire({
                                title: 'Are you sure?',
                                text: 'Do you want to mark this as a complete submission?',
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonText: 'Yes, complete',
                                cancelButtonText: 'No, cancel',
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    setForceSubmit(true);
                                    handleSubmit((data) => {
                                        onSubmit(data, true);
                                    })();
                                }
                            });
                        }}
                        disabled={isBusy || !canComplete}
                    >
                        Complete Submission
                    </Button>
                    {canSubmit && (
                        <Button
                            type="button"
                            variant="secondary"
                            css={css`
                                font-size: 16px;
                                border-radius: 16px;
                                margin-top: 1rem;
                                margin-left: 1rem;
                                padding: 0.5rem 2rem;
                                width: 25%;
                            `}
                            onClick={() => {
                                if (forceSubmit) {
                                    setForceSubmit(false);
                                } else {
                                    handleSubmit((data) => {
                                        onSubmit(data, false);
                                    })();
                                }
                            }}
                            disabled={isBusy}
                        >
                            Save as Draft
                        </Button>
                    )}
                </div>
            )}
        </Card>
    );
};

export default StaticPreview;
