import { css } from 'twin.macro';
import styled from '@emotion/styled';
import { Button, FormLabel, InputGroup, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import React, { Fragment, useState, useEffect } from 'react';
import { fetchWrapper, getTeamIdFromURL, toastWrapper, getChannelIdFromSettingsURL } from '../../../../_helpers';
import { toast } from 'react-toastify';
import AssignFormToChannelModal from '../AssignFormToChannelModal';
import { connect } from 'react-redux';
import { getRoomsByTeam } from '../../../../_redux/actions/ActionMessage';
import FormPreviewModal from '../../../Features/Teams/FormPreviewModal';
import { ActionStyles, AdminTable } from '../../../Admin/reusableStyles';
import { ToolTip } from '../../../../_elements/ToolTip';

//assets
import { AiOutlineCopy } from 'react-icons/ai';
import { BsPencil, BsTags } from 'react-icons/bs';
import { VscLoading } from 'react-icons/vsc';
import FormSvg from '../../../../assets/forms.svg';
import SortedTableHeaderIcon from '../../../Admin/SortedTableHeaderIcon';
import Pagination from '../../../Admin/Pagination';
import ChannelSelectFilter from '../../../Admin/ChannelSelectFilter';
import TagsModal from '../../Common/Tags/TagsModal';
import { TagPreviewWithContainer, AddTagsTooltip } from '../../Common/Tags/TagsPreview';
import TagSelectFilter from '../../Common/Tags/TagSelectFilter';

export const FormPackageTable = styled(AdminTable)`
    width: 100%;

    thead {
        position: sticky;
        top: 0;
        z-index: 2;
    }
    &.top-30 {
        thead {
            top: 25px !important;
        }
    }
`;

export const ScrollContainer = styled.div`
    height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
`;

export const spin = css`
    animation: spin 2s ease infinite;
    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`;

const TeamSettingFormPackage = ({ search, getRoomsByTeam, channelDetails }) => {
    const [forms, setForms] = useState([]);
    const [loadingFormPackage, setLoadingFormPackage] = useState(false);
    const teamId = getTeamIdFromURL();
    const [channel, setChannel] = useState(channelDetails?.id ? { ...channelDetails } : null);
    const [isFormArchived, setIsFormArchived] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalResults, setTotalResults] = useState(10);
    const [searchTags, setSearchTags] = useState([]);
    const [showTagsModal, setShowTagsModal] = useState(false);
    const handleShowTagsModal = (id) => setShowTagsModal(id);
    const handleCloseTagsModal = () => setShowTagsModal(false);
    const channelIdFromUrl = getChannelIdFromSettingsURL();

    const updateTagsInForms = (updatedFormResponse) => {
        const updatedForms = forms.map((form) =>
            (form?._id || form?.id) === (updatedFormResponse?._id || updatedFormResponse?.id)
                ? { ...form, ...updatedFormResponse }
                : form
        );
        setForms(updatedForms);
    };

    const [sortBy, setSortBy] = useState({
        key: 'createdAt',
        direction: 'desc',
    });

    const getFormPackage = async () => {
        try {
            setLoadingFormPackage(true);
            const res = await fetchWrapper.get(
                `forms?page=${currentPage}&limit=10${search ? `&name=${search}` : ''}&archivedForm=${isFormArchived}&sortBy=${sortBy.key}:${sortBy.direction}${channel?._id || channel?.value || channelIdFromUrl ? `&chatroom=${channel?.value || channel?._id || channelIdFromUrl}` : teamId ? `&team=${teamId}` : ''}${searchTags?.length ? `&tags=${searchTags}` : ''}`
            );
            setForms(res.results);
            setTotalResults(res.totalResults);
        } catch (error) {
            if (error.name === 'AbortError') {
                return;
            }
            toastWrapper.error(error?.message || 'Failed to load Team Plan');
        } finally {
            setLoadingFormPackage(false);
        }
    };

    useEffect(() => {
        let timer;
        if (forms.length === 0 && teamId) {
            getRoomsByTeam(teamId);
        }
        clearTimeout(timer);
        timer = setTimeout(getFormPackage, 1000);

        return () => clearTimeout(timer);
    }, [teamId, sortBy, search, currentPage, isFormArchived, channel, searchTags]);

    const handleSort = (fieldName) => {
        setSortBy({ direction: sortBy.direction === 'asc' ? 'desc' : 'asc', key: fieldName });
    };

    const handleChangeChannel = (value) => {
        setChannel(value);
    };

    const handleTagSelect = (values) => {
        setSearchTags(values?.map((tag) => tag?.value));
    };

    return (
        <div>
            <div className="top-items flex justify-content-space-between align-items-end">
                <div
                    className="row"
                    css={css`
                        position: relative;
                        display: flex;
                        width: 100%;
                        align-items: end;
                        z-index: 20;
                        gap: 5px;
                        margin-left: 4px;
                    `}
                >
                    <div
                        css={css`
                            width: 264px;
                        `}
                    >
                        <InputGroup className="flex-column">
                            <FormLabel className="mr-2 ">Channel: </FormLabel>
                            <ChannelSelectFilter
                                disabled={!!channelIdFromUrl}
                                selectedOption={channelDetails?.id ? channelDetails : channel}
                                selectedRoomOption={channel}
                                onRoomSelect={handleChangeChannel}
                                placeholder="Enter Channel Name"
                                className="bolder"
                                css={css`
                                    width: 264px;
                                `}
                            />
                        </InputGroup>
                    </div>
                    <div
                        css={css`
                            min-width: 264px;
                            width: clamp(264px, max-content);
                            margin-right: 4rem;
                        `}
                    >
                        <InputGroup className="flex-column">
                            <FormLabel className="mr-2 ">Tags: </FormLabel>
                            <TagSelectFilter
                                className="bolder"
                                css={css`
                                    width: 264px;
                                `}
                                handleSelectCallback={handleTagSelect}
                                type="form"
                                disabled={loadingFormPackage}
                            />
                        </InputGroup>
                    </div>
                </div>
                <div
                    css={css`
                        display: flex;
                        flex-direction: row;
                        gap: 4px;
                    `}
                >
                    <Nav
                        variant="pills"
                        className="pl-2 py-1"
                        css={css`
                            background: #e1e1e1;
                            border-radius: 5px;
                            width: 17rem;
                        `}
                    >
                        <Nav.Item className="ml-1">
                            <Nav.Link
                                as={Button}
                                variant={!isFormArchived ? 'primary' : 'link'}
                                onClick={() => setIsFormArchived(false)}
                                style={{ fontSize: '1.6rem' }}
                                className={!isFormArchived ? 'bg-primary text-white m-1 px-2 py-1' : 'm-1 px-2 py-1'}
                            >
                                Active
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="ml-1">
                            <Nav.Link
                                as={Button}
                                variant={isFormArchived ? 'primary' : 'link'}
                                onClick={() => setIsFormArchived(true)}
                                style={{ fontSize: '1.6rem' }}
                                className={isFormArchived ? 'bg-primary text-white m-1 px-2 py-1' : 'm-1 px-2 py-1'}
                            >
                                Archived
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Link
                        to={'/form-builder/'}
                        css={css`
                            color: white;
                            text-decoration: none;
                            a:hover {
                                color: white;
                                text-decoration: none;
                            }
                        `}
                    >
                        <Button variant="primary" className="btn px-4 py-3 ">
                            <h4
                                className="mb-0"
                                css={css`
                                    width: 12rem;
                                `}
                            >
                                Build a New Form
                            </h4>
                        </Button>
                    </Link>
                </div>
            </div>
            {loadingFormPackage ? (
                <h4 className="text-center">
                    <VscLoading css={spin} fontSize="42" />
                </h4>
            ) : forms.length > 0 ? (
                <div className="custom-card">
                    <FormPackageTable className="w-100">
                        <thead>
                            <tr>
                                <th
                                    onClick={() => handleSort('createdAt')}
                                    css={css`
                                        color: inherit;
                                        cursor: pointer;
                                    `}
                                >
                                    S.N
                                    <SortedTableHeaderIcon sortConfig={sortBy} fieldName={'createdAt'} />
                                </th>
                                <th>Form Name</th>
                                <th className="text-center">Form Packages</th>
                                <th
                                    className="text-center"
                                    css={css`
                                        width: 5rem;
                                    `}
                                >
                                    Submissions
                                </th>
                                <th className="text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {forms.length > 0 ? (
                                <Fragment>
                                    {forms
                                        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
                                        .map((formData, index) => {
                                            return (
                                                <tr key={`${formData._id}_${10 * (currentPage - 1) + index + 1}`}>
                                                    <td>{10 * (currentPage - 1) + index + 1}</td>
                                                    <td
                                                        className="d-flex align-items-center"
                                                        css={css`
                                                            flex: 1;
                                                        `}
                                                    >
                                                        <div
                                                            css={css`
                                                                display: flex;
                                                                flex-direction: column;
                                                                gap: 0.5rem;
                                                            `}
                                                        >
                                                            <div
                                                                css={css`
                                                                    display: flex;
                                                                    flex-direction: row;
                                                                `}
                                                            >
                                                                <span
                                                                    css={css`
                                                                        white-space: nowrap;
                                                                        overflow: hidden;
                                                                        text-overflow: ellipsis;
                                                                        max-width: 50rem;
                                                                    `}
                                                                >
                                                                    {formData?.name}
                                                                </span>
                                                                {formData?.archived ? (
                                                                    <span className="badge badge-danger mx-2">
                                                                        Archived
                                                                    </span>
                                                                ) : null}
                                                                {
                                                                    <span
                                                                        css={css`
                                                                            width: fit-content;
                                                                            cursor: pointer;
                                                                            margin-left: 10px;
                                                                        `}
                                                                        onClick={() =>
                                                                            handleShowTagsModal(
                                                                                formData?._id || formData?.id
                                                                            )
                                                                        }
                                                                    >
                                                                        <AddTagsTooltip />
                                                                    </span>
                                                                }
                                                            </div>
                                                            <TagPreviewWithContainer
                                                                tags={formData?.tags || []}
                                                                style={{ maxWidth: '50rem' }}
                                                            />
                                                        </div>
                                                        {showTagsModal === (formData?._id || formData?.id) ? (
                                                            <TagsModal
                                                                show={!!showTagsModal}
                                                                handleClose={handleCloseTagsModal}
                                                                type={'form'}
                                                                tagAction={'assign'}
                                                                objectId={formData?._id || formData?.id}
                                                                prevTags={formData?.tags}
                                                                responseCallback={updateTagsInForms}
                                                            />
                                                        ) : null}
                                                    </td>
                                                    <td
                                                        css={css`
                                                            text-align: center;
                                                        `}
                                                    >
                                                        {formData.formPackage && formData.formPackage.length ? (
                                                            <div
                                                                css={css`
                                                                    display: flex;
                                                                    flex-direction: column;
                                                                    gap: 3px;
                                                                    align-items: flex-start;
                                                                `}
                                                            >
                                                                {formData.formPackage.map((packageName, idx) => (
                                                                    <span className="badge badge-warning" key={idx}>
                                                                        {packageName}
                                                                    </span>
                                                                ))}
                                                            </div>
                                                        ) : (
                                                            <span>-</span>
                                                        )}
                                                    </td>
                                                    <td
                                                        css={css`
                                                            text-align: center;
                                                        `}
                                                    >
                                                        {formData?.noOfSubmission}
                                                    </td>
                                                    <td>
                                                        <ActionStyles className="mt-2">
                                                            <FormPreviewModal form={formData} />
                                                            <Link to={`/form-library/copy/${formData._id}`}>
                                                                <ToolTip
                                                                    text={'Copy'}
                                                                    cssStyles={
                                                                        'top:-70% !important; min-width: fit-content !important'
                                                                    }
                                                                >
                                                                    <AiOutlineCopy className="icon" />
                                                                </ToolTip>
                                                            </Link>

                                                            <Fragment>
                                                                {(!formData.formPackage ||
                                                                    !formData.formPackage.length) &&
                                                                formData.noOfSubmission === 0 ? (
                                                                    <Link to={`/form-library/edit/${formData._id}`}>
                                                                        <ToolTip
                                                                            text={'Edit'}
                                                                            side={'top'}
                                                                            cssStyles={
                                                                                'top:-70% !important; min-width: fit-content !important'
                                                                            }
                                                                        >
                                                                            <BsPencil className="icon edit" />
                                                                        </ToolTip>
                                                                    </Link>
                                                                ) : (
                                                                    <ToolTip
                                                                        text={'Edit'}
                                                                        side={'top'}
                                                                        cssStyles={
                                                                            'top:-70% !important; min-width: fit-content !important'
                                                                        }
                                                                    >
                                                                        <BsPencil className="icon disabled" />
                                                                    </ToolTip>
                                                                )}
                                                            </Fragment>
                                                            {/* {type === 'archive' ? (
																	<ArchiveForms
																		formId={item._id}
																		archived={false}
																		fetchForms={fetchForms}
																	/>
																) : (
																	<ArchiveForms
																		formId={item._id}
																		archived={true}
																		fetchForms={fetchForms}
																	/>
																)} */}

                                                            {/* to assing i need form name, chatroomId, form data
													and to check if the channel already has the form i can send chatroom array */}
                                                            {channelIdFromUrl ? null : (
                                                                <AssignFormToChannelModal
                                                                    formName={formData.name}
                                                                    formId={formData._id}
                                                                    elementData={formData.data}
                                                                    assignedChatrooms={formData.chatroom}
                                                                    teamId={teamId}
                                                                    getFormPackage={getFormPackage}
                                                                />
                                                            )}
                                                        </ActionStyles>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </Fragment>
                            ) : (
                                <tr>
                                    <td colSpan="100">
                                        <h4 className="text-center">No Forms Found</h4>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </FormPackageTable>
                </div>
            ) : (
                <div
                    css={css`
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                    `}
                >
                    <img
                        src={FormSvg}
                        alt={'no forms found'}
                        css={css`
                            width: 350px;
                            margin: 1rem 0;
                        `}
                    />
                    <h2 className="text-center">No Forms in this Team</h2>
                </div>
            )}
            <div className="p-4">
                <Pagination
                    totalRecords={totalResults}
                    key={totalResults}
                    pageLimit={10}
                    pageNeighbours={1}
                    onPageChanged={(data) => {
                        setCurrentPage(Math.max(1, data.currentPage));
                    }}
                />
            </div>
        </div>
    );
};
const mapStateToProps = (state) => ({
    rooms: state.rooms.teamRooms,
});

export default connect(mapStateToProps, { getRoomsByTeam })(TeamSettingFormPackage);
